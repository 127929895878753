import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  map, equals, or, pipe, head, takeLast, reject,
  length, filter, propEq, isEmpty, pluck, pick
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
// import { jsPDF } from 'jspdf'
import { Creators as Actions } from '../actions'
import { ordersSelector, activeCouriersSelector } from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import IconMoreVert from '@material-ui/icons/MoreVert'

// Components
import Header from '../components/Header'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridOrders from '../components/GridOrders'
import DialogOrderStatusChange from '../components/DialogOrderStatusChange'
import PrintButton from '../components/PrintButton'
import PrintWrapper from '../components/PrintWrapper'
import PrintOrder from '../components/PrintOrder'
import PrintOrderSummary from '../components/PrintOrderSummary'
import DialogGiftMessages from '../components/DialogGiftMessages'

const filterSelected = (data, selection) => map(
  index => data[index],
  selection
)

const filterGiftMessages = (data) => pipe(
  map(pick(['order_reference', 'gift_message'])),
  reject(x => isEmpty(x.gift_message))
)(data)

const styles = theme => ({

})


class Orders extends Component {
  state = {
    expandedRowId: undefined,
    optionsEl: null,
    // Change Status
    changingStatus: false,
    orderStatusId: 0,
    orderStatusComment: '',
    print: false,
    // Gift Messages
    printingGiftMessages: false
  }

  componentDidMount() {
    this.handleMount()
  }

  handleMount = () => {
    this.props.getOrdersAttempt({
      // currentPage: 0
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // All orders status changed?
    if (
      this.props.uiLoadingChangeStatus !== nextProps.uiLoadingChangeStatus
    ) {
      // trigger print
      // if (!nextProps.uiLoadingChangeStatus && this.state.print) {
      //   this.setState({ print: false })
      // }

      // close modal
      if (nextProps.uiLoadingChangeStatus && !this.state.print) {
        this.toggleChangingStatus()
      }

      // reset state
      this.setState({
        orderStatusId: 0,
        orderStatusComment: '',
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  toggleOptions = event => {
    this.setState({
      optionsEl: this.state.optionsEl ? null : event.currentTarget,
    })
  }

  toggleChangingStatus = () => {
    this.setState({
      optionsEl: null,
      changingStatus: !this.state.changingStatus,
    })
  }

  toggleGiftMessages = () => {
    // TODO clear selected orders ???
    this.setState({
      optionsEl: null,
      printingGiftMessages: !this.state.printingGiftMessages,
    })
  }

  changeExpandedRows = rows => {
    // NOTE Expanding only 1 row at the time
    this.setState({ expandedRowId: head(takeLast(1, rows)) })
  }

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  // TODO need to fix PrintButton
  // We want to set order status and then trigger print
  // handlePrintSelected = () => {
  //   const { orderStatuses } = this.props
  //   const pickingStatusId = pipe(
  //     find(propEq('title', 'Picking')),
  //     prop('id')
  //   )(orderStatuses)
  //
  //   if (pickingStatusId) {
  //     this.setState({
  //       orderStatusId: pickingStatusId,
  //       print: true,
  //     }, this.handleStatusChangeSubmit)
  //   }
  // }

  handleStatusChangeSubmit = () => {
    const { data, selection, changeOrdersStatusAttempt } = this.props
    const { orderStatusId, orderStatusComment } = this.state

    const selectedOrders = pipe(
      data => filterSelected(data, selection),
      pluck('order_reference'),
    )(data)

    this.setState({
      optionsEl: null,
    }, () => changeOrdersStatusAttempt(
      selectedOrders,
      Number(orderStatusId),
      orderStatusComment
    ))
  }

  render() {
    const {
      isAdmin,
      // classes,
      // Grid
      uiLoadingGet,
      orderStatuses,
      data,
      columns,
      columnExtensions,
      visibilityColumnExtensions,
      hiddenColumnNames,
      onColumnVisibilityChange,
      sorting,
      onSortingChange,
      selection,
      onSelectionChange,
      filters,
      onFiltersChange,
      currentPage,
      onCurrentPageChange,
      totalCount,
      pageSizes,
      pageSize,
      onPageSizeChange,
      orderItemsColumns,
      orderItemsColumnExtensions,
      orderItemsSorting,
      onOrderItemsSortingChange,
      // Change Status
      uiLoadingChangeStatus,
      statusChanges,
      // Couriers
      couriers,
    } = this.props
    const {
      expandedRowId,
      optionsEl,
      changingStatus,
      orderStatusId,
      orderStatusComment,
      printingGiftMessages
    } = this.state
    const selectedOrders = filterSelected(data, selection)
    const giftMessages = filterGiftMessages(selectedOrders)

    let loading = null

    if (uiLoadingGet) loading = <Loading message="Loading orders..." />
    if (uiLoadingChangeStatus) {
      const statusChanged = filter(propEq('statusChanged', true), statusChanges)
      loading = (
        <Loading
          message={`Status changed for ${length(statusChanged)} of ${length(statusChanges)} orders.`}
        />
      )
    }

    return (
      <div>
        <Header
          title={'Orders'}
          subtitle={`Browsing page ${currentPage + 1}`}
        >
          <RefreshButton
            onClick={this.handleMount}
          />
          <IconButton
            onClick={this.toggleOptions}
          >
            <IconMoreVert />
          </IconButton>
          <Menu
            anchorEl={optionsEl}
            open={Boolean(optionsEl)}
            onClose={this.toggleOptions}
          >
            <PrintButton
              trigger={() => (
                <MenuItem
                  // onClick={this.handlePrintSelected}
                  disabled={isEmpty(selectedOrders)}
                >
                  {`Print (${length(selectedOrders)})`}
                </MenuItem>
              )}
              content={() => this.printComponent}
            />
            <PrintButton
              trigger={() => (
                <MenuItem
                  // onClick={this.handlePrintSelected}
                  disabled={isEmpty(selectedOrders)}
                >
                  {`Print & Pick (${length(selectedOrders)})`}
                </MenuItem>
              )}
              content={() => this.printAndPickComponent}
            />
            {isAdmin && (
              <MenuItem
                onClick={this.toggleGiftMessages}
                disabled={isEmpty(giftMessages)}
              >
                {`Print Gift Messages (${length(giftMessages)})`}
              </MenuItem>
            )}
            <MenuItem
              onClick={this.toggleChangingStatus}
              disabled={isEmpty(selectedOrders)}
            >
              {`Change Status (${length(selectedOrders)})`}
            </MenuItem>
          </Menu>
        </Header>
        <Paper>
          <GridOrders
            statuses={orderStatuses.asMutable()}
            rows={data}
            couriers={couriers}
            columns={columns.asMutable()}
            columnExtensions={columnExtensions.asMutable()}
            visibilityColumnExtensions={visibilityColumnExtensions}
            hiddenColumnNames={hiddenColumnNames}
            onColumnVisibilityChange={onColumnVisibilityChange}
            sorting={sorting.asMutable()}
            onSortingChange={onSortingChange}
            expandedRowIds={[expandedRowId]}
            onExpandedRowIdsChange={this.changeExpandedRows}
            selection={selection.asMutable()}
            onSelectionChange={onSelectionChange}
            filters={filters.asMutable()}
            onFiltersChange={onFiltersChange}
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            pageSizes={pageSizes}
            orderItemsColumns={orderItemsColumns.asMutable()}
            orderItemsColumnExtensions={orderItemsColumnExtensions.asMutable()}
            orderItemsSorting={orderItemsSorting.asMutable()}
            onOrderItemsSortingChange={onOrderItemsSortingChange}
          />
        </Paper>
        {!isEmpty(selectedOrders) && (
          <DialogOrderStatusChange
            open={changingStatus}
            onClose={this.toggleChangingStatus}
            disableBackdropClick={uiLoadingChangeStatus}
            disableEscapeKeyDown={uiLoadingChangeStatus}
            statuses={orderStatuses}
            statusId={Number(orderStatusId)}
            comment={orderStatusComment}
            onChange={this.handleInputChange}
            onSubmit={this.handleStatusChangeSubmit}
            submitting={uiLoadingChangeStatus}
          />
        )}
        {!isEmpty(selectedOrders) && (
          <PrintWrapper ref={c => (this.printComponent = c)}>
            {selectedOrders.map(order => (
              <PrintOrder
                key={`print-order-${order.id}`}
                isAdmin={isAdmin}
                order={order}
                couriers={couriers}
              />
            ))}
          </PrintWrapper>
        )}
        {!isEmpty(selectedOrders) && (
          <PrintWrapper ref={c => (this.printAndPickComponent = c)}>
            {selectedOrders.map(order => (
              <PrintOrder
                key={`print-order-${order.id}`}
                isAdmin={isAdmin}
                order={order}
                couriers={couriers}
              />
            ))}
            <PrintOrderSummary
              key={`print-order-summary`}
              orders={selectedOrders}
            />
          </PrintWrapper>
        )}
        {!isEmpty(giftMessages) && (
          <DialogGiftMessages
            open={printingGiftMessages}
            onClose={this.toggleGiftMessages}
            orders={giftMessages}
          />
        )}
        {loading}
      </div>
    )
  }
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
  // Grid
  getOrdersAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  orderStatuses: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  visibilityColumnExtensions: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  sorting: PropTypes.array.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  selection: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  orderItemsColumns: PropTypes.array.isRequired,
  orderItemsColumnExtensions: PropTypes.array.isRequired,
  orderItemsSorting: PropTypes.array.isRequired,
  onOrderItemsSortingChange: PropTypes.func.isRequired,
  // Change Status
  changeOrdersStatusAttempt: PropTypes.func.isRequired,
  uiLoadingChangeStatus: PropTypes.bool.isRequired,
  statusChanges: PropTypes.array.isRequired,
  // Couriers
  couriers: PropTypes.array.isRequired,
}

const {
  watchOrdersFilter,
  getOrdersAttempt,
  setOrdersGridState,
  changeOrdersStatusAttempt,
} = Actions

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  ...state.orders,
  data: ordersSelector(state),
  orderStatuses: state.app.orderStatuses,
  couriers: activeCouriersSelector(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrdersAttempt,
  onColumnVisibilityChange: columnNames => setOrdersGridState('hiddenColumnNames', columnNames),
  onSortingChange: sorting => getOrdersAttempt({
    sorting: sorting
  }),
  onSelectionChange: selection => setOrdersGridState('selection', selection),
  onFiltersChange: filters => watchOrdersFilter({
    currentPage: 0,
    filters: filters,
    selection: []
  }),
  onCurrentPageChange: currentPage => getOrdersAttempt({
    currentPage: currentPage
  }),
  onPageSizeChange: pageSize => getOrdersAttempt({
    currentPage: 0,
    pageSize: pageSize
  }),
  onOrderItemsSortingChange: sorting => setOrdersGridState('orderItemsSorting', sorting),
  changeOrdersStatusAttempt,
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Orders)
