import { max } from 'ramda'
import { mapPostcodes } from './shipping-helpers.js'

const scottishHighlandsAndIslands = {
  IV: [],
  FK: [[17, 21]],
  HS: [],
  KA: [[27, 28]],
  KW: [],
  PA: [[20, 49], [60, 78]],
  PH: [[19, 26], [30, 44], [49, 50]],
  ZE: []
}

const isleOfWight = {
  PO: [[30, 41]]
}

const islesOfScilly = {
  TR: [[21, 25]]
}

// TODO pricing
const postcodesScottishHighlandsAndIslands = mapPostcodes(scottishHighlandsAndIslands)
const postcodesIsleOfWight = mapPostcodes(isleOfWight)

const postcodesIslesOfScilly = mapPostcodes(islesOfScilly)
const postcodesIsleOfMan = ['IM']
const postcodesChannelIslands = ['JE', 'GY']
const postcodesNorthernIreland = ['BT']


export default {
  'name': 'Parcelforce',
  'acronym': 'PF',
  'zones': [
    {
      'name': 'UK Mainland',
      'postcodesInclude': [],
      'postcodesExclude': [
        ...postcodesScottishHighlandsAndIslands,
        ...postcodesIsleOfWight,
        ...postcodesIslesOfScilly,
        ...postcodesIsleOfMan,
        ...postcodesChannelIslands,
        ...postcodesNorthernIreland
      ],
      'fuelSurcharge': 15.9,
      'services': [
        {
          'code': 'nextDay9',
          'name': 'Next Day 9:00',
          '1stParcel': 29.21,
          '2ndParcel+': 18.73
        },
        {
          'code': 'nextDay1030',
          'name': 'Next Day 10:30',
          '1stParcel': 17.63,
          '2ndParcel+': 12.29
        },
        {
          'code': 'nextDay12',
          'name': 'Next Day 12:00',
          '1stParcel': 7.07,
          '2ndParcel+': 5.23
        },
        {
          'code': 'nextDay',
          'name': 'Next Day',
          '1stParcel': 5.92,
          '2ndParcel+': 3.09,
          'default': true
        },
        {
          'code': 'saturday9',
          'name': 'Saturday 9:00',
          '1stParcel': 29.21,
          '2ndParcel+': 18.73,
          'surcharge': 12.00,
          'notes': '£12 surcharge per consignment'
        },
        {
          'code': 'saturday1030',
          'name': 'Saturday 10:30',
          '1stParcel': 17.63,
          '2ndParcel+': 12.29,
          'surcharge': 12.00,
          'notes': '£12 surcharge per consignment'
        },
        {
          'code': 'saturday12',
          'name': 'Saturday 12:00',
          '1stParcel': 7.07,
          '2ndParcel+': 5.23,
          'surcharge': 12.00,
          'notes': '£12 surcharge per consignment'
        },
        {
          'code': 'saturday',
          'name': 'Saturday',
          '1stParcel': 5.92,
          '2ndParcel+': 3.09,
          'surcharge': 12.00,
          'notes': '£12 surcharge per consignment'
        }
      ]
    },
    {
      'name': 'Scottish Highlands and Islands',
      'postcodesInclude': postcodesScottishHighlandsAndIslands,
      'postcodesExclude': [],
      'fuelSurcharge': 15.9,
      'surchargeFunction': (price) => {
        const surcharge = price * 1.2
        return max(7.75, surcharge)
      },
      // TODO add saturday (highlands is tricky)
      'services': [
        {
          'code': 'nextDay9',
          'name': 'Next Day 9:00',
          '1stParcel': 29.21,
          '2ndParcel+': 18.73,
          'notes': 'Either - £7.75 or 120% surcharge whichever is the greater on all services'
        },
        {
          'code': 'nextDay1030',
          'name': 'Next Day 10:30',
          '1stParcel': 17.63,
          '2ndParcel+': 12.29,
          'notes': 'Either - £7.75 or 120% surcharge whichever is the greater on all services'
        },
        {
          'code': 'nextDay12',
          'name': 'Next Day 12:00',
          '1stParcel': 7.07,
          '2ndParcel+': 5.23,
          'notes': 'Either - £7.75 or 120% surcharge whichever is the greater on all services'
        },
        {
          'code': 'nextDay',
          'name': 'Next Day',
          '1stParcel': 5.92,
          '2ndParcel+': 3.09,
          'default': true,
          'notes': 'Either - £7.75 or 120% surcharge whichever is the greater on all services'
        }
      ]
    },
    {
      'name': 'Isles of Scilly & Isle of Man',
      'postcodesInclude': [
        ...postcodesIslesOfScilly,
        ...postcodesIsleOfMan
      ],
      'postcodesExclude': [],
      'fuelSurcharge': 15.9,
      // TODO add Two Day for 9.25 (* 1.65)
      'surchargeFunction': (price) => {
        const surcharge = price * 1.65
        return max(13.25, surcharge)
      },
      // TODO add saturday
      'services': [
        {
          'code': 'nextDay9',
          'name': 'Next Day 9:00',
          '1stParcel': 29.21,
          '2ndParcel+': 18.73,
          'notes': 'Either - £13.25 or 165% surcharge whichever is the greater on all services'
        },
        {
          'code': 'nextDay1030',
          'name': 'Next Day 10:30',
          '1stParcel': 17.63,
          '2ndParcel+': 12.29,
          'notes': 'Either - £13.25 or 165% surcharge whichever is the greater on all services'
        },
        {
          'code': 'nextDay12',
          'name': 'Next Day 12:00',
          '1stParcel': 7.07,
          '2ndParcel+': 5.23,
          'notes': 'Either - £13.25 or 165% surcharge whichever is the greater on all services'
        },
        {
          'code': 'nextDay',
          'name': 'Next Day',
          '1stParcel': 5.92,
          '2ndParcel+': 3.09,
          'default': true,
          'notes': 'Either - £13.25 or 165% surcharge whichever is the greater on all services'
        }
      ]
    },
    {
      'name': 'Isle of Wight',
      'postcodesInclude': postcodesIsleOfWight,
      'postcodesExclude': [],
      'fuelSurcharge': 15.9,
      // TODO add saturday
      'services': [
        {
          'code': 'nextDay9',
          'name': 'Next Day 9:00',
          '1stParcel': 29.21,
          '2ndParcel+': 18.73,
          'surcharge': 5.00,
          'notes': '£5 surcharge per consignment'
        },
        {
          'code': 'nextDay1030',
          'name': 'Next Day 10:30',
          '1stParcel': 17.63,
          '2ndParcel+': 12.29,
          'surcharge': 5.00,
          'notes': '£5 surcharge per consignment'
        },
        {
          'code': 'nextDay12',
          'name': 'Next Day 12:00',
          '1stParcel': 7.07,
          '2ndParcel+': 5.23,
          'surcharge': 5.00,
          'notes': '£5 surcharge per consignment'
        },
        {
          'code': 'nextDay',
          'name': 'Next Day',
          '1stParcel': 5.92,
          '2ndParcel+': 3.09,
          'surcharge': 5.00,
          'default': true,
          'notes': '£5 surcharge per consignment'
        }
      ]
    },
    {
      'name': 'Northern Ireland',
      'postcodesInclude': postcodesNorthernIreland,
      'postcodesExclude': [],
      'fuelSurcharge': 15.9,
      // TODO add Next Day for 13.25 (* 1.65)
      'surchargeFunction': (price) => {
        const surcharge = price * 1.65
        return max(13.25, surcharge)
      },
      // 'surchargeFunction': (price) => {
      //   const surcharge = price * 0.7
      //   return max(9.25, surcharge)
      // },
      // TODO add saturday
      'services': [
        {
          'code': 'twoDay',
          'name': 'Two Day',
          '1stParcel': 5.92,
          '2ndParcel+': 3.09,
          'default': true,
          'notes': 'Either £13.25 or 165% whichever is the greater on services 9AM-24 Hour or £9.25 or 70% whichever is the greater on 48 Hour only'
        }
      ]
    }
  ]
}
