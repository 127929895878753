import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  equals, prop, path, nth, or, length, contains, join,
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { replace, goBack } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { Creators as Actions } from '../actions'
import {
  shipmentSelector,
  shipmentProductsSelector,
  shipmentItemsSelector
} from '../util/selectors'
import { formatDate } from '../util/helpers'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import IconVisibility from '@material-ui/icons/Visibility'
import IconAssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'
import IconLaunch from '@material-ui/icons/Launch'
import IconDelete from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

// React Grid
import {
  Grid as ReactGrid,
  Table, TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import Header from '../components/Header'
import GoBackButton from '../components/GoBackButton'
import RefreshButton from '../components/RefreshButton'
import Empty from '../components/Empty'
import Loading from '../components/Loading'
import GridOrderItems from '../components/GridOrderItems'

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  flex: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
})


class Shipment extends Component {
  state = {
    shipmentId: path(['params', 'shipmentId'], this.props.match),
    tab: 0,
    deleting: false,
    forceDelete: false,
  }

  componentDidMount() {
    this.handleMount()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Shipment deleted?
    if (
      !equals(this.props.data, nextProps.data)
      && !path(['data', 'id'], nextProps)
    ) {
      this.props.replace('/shipments')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  componentWillUnmount() {
    this.props.resetShipmentState()
  }

  handleMount = () => {
    this.props.getShipmentAttempt(this.state.shipmentId)
  }

  handleTabsChange = (event, value) => {
    this.setState({ tab: value })
  }

  handleDeliverShipmentSubmit = () => {
    const { deliverShipmentAttempt } = this.props
    const { shipmentId } = this.state
    deliverShipmentAttempt(shipmentId)
  }

  toggleDeleting = () => {
    this.setState({
      deleting: !this.state.deleting,
    })
  }

  handleForceDeleteChange = event => {
    this.setState({
      forceDelete: event.target.checked,
    })
  }

  handleDeleteShipmentSubmit = () => {
    const { data: { courier_id }, deleteShipmentAttempt } = this.props
    const { shipmentId, forceDelete } = this.state
    this.toggleDeleting()
    let data = {}
    if (forceDelete) data = { force_delete: forceDelete }
    deleteShipmentAttempt(courier_id, shipmentId, data)
  }

  // Grid
  headerCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // No label and sorting
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (contains(columnName, [
      'description', 'location', 'supplier', 'comments', 'status', 'signedBy'
    ])) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Actions
    if (equals(columnName, 'action')) {
      const { location: routerLocation } = this.props
      const pathname = prop('pathname', routerLocation)
      const skuCode = path(['row', 'sku'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <LinkButton
            title={`Open product ${skuCode}`}
            to={{
              pathname: `/products/${skuCode}`,
              state: { from: pathname }
            }}
          >
            <IconVisibility color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = (colSpan, message) => {
    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{message}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      classes,
      location: routerLocation,
      history: routerHistory,
      goBack,
      // Grid
      uiLoadingGet,
      uiLoadingUpdate,
      uiLoadingDelete,
      data,
      error,
      shipmentProducts,
      shipmentProductsColumns,
      shipmentProductsColumnExtensions,
      shipmentProductsSorting,
      onShipmentProductsSortingChange,
      shipmentItems,
      shipmentItemsColumns,
    } = this.props
    const pathname = prop('pathname', routerLocation)
    const shipmentReference = prop('shipment_reference', data)
    const trackingUrl = prop('tracking_url', data)
    const courier = prop('courier', data)
    const service = prop('service_name', data)
    const courierId = prop('courier_id', data)
    const courierReference = prop('courier_reference', data)
    const orderBacklink = path(['order', 'backlink'], data)
    const orderReference = path(['order', 'order_reference'], data)
    const consignee = join(' ', [
      path(['order', 'customer_delivery_first_name'], data),
      path(['order', 'customer_delivery_last_name'], data)
    ])
    const dateDispatched = prop('dispatched_at', data)
    const dateDelivered = prop('delivered_at', data)
    const {
      shipmentId,
      tab,
      deleting,
      forceDelete,
    } = this.state
    const shipmentItem = nth(tab, shipmentItems)
    let loading = null
    let tracking = []

    if (shipmentItem) tracking = prop('tracking_meta', shipmentItem)

    if (uiLoadingGet) {
      loading = 'Loading shipment...'
    } else if (uiLoadingUpdate) {
      loading = 'Updating shipment...'
    } else if (uiLoadingDelete) {
      loading = 'Deleting shipment...'
    }

    return (
      <div className={classes.container}>
        <Header
          title={`Shipment #${shipmentReference || shipmentId}`}
          subtitle={`Track shipment`}
        >
          <GoBackButton
            onBack={goBack}
            history={routerHistory}
          />
          <RefreshButton
            onClick={this.handleMount}
          />
          {shipmentReference && !dateDelivered && (
            <Tooltip
              id="tooltip-deliver"
              title="Mark shipment as delivered"
              placement="left"
            >
              <IconButton onClick={this.handleDeliverShipmentSubmit}>
                <IconAssignmentTurnedIn />
              </IconButton>
            </Tooltip>
          )}
          {shipmentReference && orderBacklink && (
            <Tooltip
              id="tooltip-backlink"
              title="Order backlink"
              placement="left"
            >
              <IconButton
                onClick={() => window.open(orderBacklink, '_blank')}
              >
                <IconLaunch color="action" />
              </IconButton>
            </Tooltip>
          )}
          {shipmentReference && (
            <Tooltip
              id="tooltip-delete"
              title="Cancel & delete shipment"
              placement="left"
            >
              <IconButton onClick={this.toggleDeleting}>
                <IconDelete color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Header>
        {
          shipmentReference
          ? (
            <Grid container spacing={3}>
              {/* Shipment details */}
              <Grid item xs={12} sm={6}>
                <Typography
                  color="textSecondary"
                >
                  {`Courier: ${courier}`}
                </Typography>
                <Typography
                  color="textSecondary"
                >
                  {`Service: ${service || 'n/a'}`}
                </Typography>
                {trackingUrl
                  ? (
                    <Typography
                      color="textSecondary"
                      dangerouslySetInnerHTML={{ __html: `Reference: <a href="${trackingUrl}" target="_blank">${courierReference}</a>` }}
                    />
                  )
                  : (
                    <Typography
                      color="textSecondary"
                    >
                      {`Reference: ${courierReference ? courierReference : 'n/a'}`}
                    </Typography>
                  )
                }
                <Typography
                  color="textSecondary"
                >
                  {'Order:'} <Link to={`/orders/${orderReference}`} state={{ from: pathname }}>{orderReference}</Link>
                </Typography>
                <Typography
                  color="textSecondary"
                >
                  {`Consignee: ${consignee}`}
                </Typography>
                <Typography
                  color="textSecondary"
                >
                  {`Dispatched: ${dateDispatched ? formatDate(dateDispatched) : 'n/a'}`}
                </Typography>
                <Typography
                  color="textSecondary"
                >
                  {`Delivered: ${dateDelivered ? formatDate(dateDelivered) : 'n/a'}`}
                </Typography>
              </Grid>
              {/* Products */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Shipment Products'}
                    </Typography>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <GridOrderItems
                    rows={shipmentProducts}
                    columns={shipmentProductsColumns.asMutable()}
                    columnExtensions={shipmentProductsColumnExtensions.asMutable()}
                    sorting={shipmentProductsSorting.asMutable()}
                    onSortingChange={onShipmentProductsSortingChange}
                  />
                </Paper>
              </Grid>
              {/* History */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  {
                    shipmentItem
                    ? (
                      <AppBar position="static" color="inherit">
                        <Tabs
                          value={tab}
                          onChange={this.handleTabsChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="off"
                        >
                          {shipmentItems.map((item, key) => (
                            <Tab key={item.id} label={`Parcel #${key+1}`} />
                          ))}
                        </Tabs>
                      </AppBar>
                    )
                    : (
                      <Toolbar disableGutters>
                        <Typography className={classes.flex} variant="subtitle1">
                          {'Shipment History'}
                        </Typography>
                      </Toolbar>
                    )
                  }
                  <Divider className={classes.divider} />
                  <ReactGrid
                    rows={tracking}
                    columns={shipmentItemsColumns.asMutable()}
                  >
                    <Table
                      rowComponent={this.rowComponent}
                      cellComponent={this.cellComponent}
                      noDataCellComponent={() =>
                        this.cellComponentNoData(
                          length(shipmentItemsColumns),
                          'No history.'
                        )
                      }
                    />
                    <TableHeaderRow />
                  </ReactGrid>
                </Paper>
              </Grid>
            </Grid>
          )
          : (
            error && <Empty message={error || 'No data.'} />
          )
        }
        {/* TODO this modal needs better info */}
        {shipmentReference && (
          <Dialog
            open={deleting}
            onClose={this.toggleDeleting}
            disableEnforceFocus
            disableRestoreFocus
          >
            <DialogTitle>Are you sure you want to delete this shipment?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                This cannot be undone.
              </DialogContentText>
              {
                contains(courierId, ['apc', 'ups', 'parcelforce', 'packfleet'])
                ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={forceDelete}
                        onChange={this.handleForceDeleteChange}
                        value="forceDelete"
                      />
                    }
                    label="Force delete? (In case courier does not allow)"
                  />
                )
                : null
              }
              {
                contains(courierId, ['dpd'])
                ? (
                  <Typography variant="subtitle1" paragraph>
                    {'Courier does not support this action. Please make sure you VOID the shipment from courier app.'}
                  </Typography>
                )
                : null
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleDeleting}>
                {'Cancel'}
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: '10px'}}
                color="primary"
                onClick={this.handleDeleteShipmentSubmit}
              >
                {'Delete'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {loading && <Loading message={loading} />}
      </div>
    )
  }
}

Shipment.propTypes = {
  classes: PropTypes.object.isRequired,
  replace: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  // Grid
  getShipmentAttempt: PropTypes.func.isRequired,
  deliverShipmentAttempt: PropTypes.func.isRequired,
  deleteShipmentAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  uiLoadingUpdate: PropTypes.bool.isRequired,
  uiLoadingDelete: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  shipmentProducts: PropTypes.array.isRequired,
  shipmentProductsColumns: PropTypes.array.isRequired,
  shipmentProductsColumnExtensions: PropTypes.array.isRequired,
  shipmentProductsSorting: PropTypes.array.isRequired,
  onShipmentProductsSortingChange: PropTypes.func.isRequired,
  shipmentItems: PropTypes.array.isRequired,
  shipmentItemsColumns: PropTypes.array.isRequired,
  resetShipmentState: PropTypes.func.isRequired,
}

const {
  getShipmentAttempt,
  setShipmentProductsGridState,
  deliverShipmentAttempt,
  deleteShipmentAttempt,
  resetShipmentState,
} = Actions

const mapStateToProps = state => ({
  ...state.shipment,
  data: shipmentSelector(state),
  shipmentProducts: shipmentProductsSelector(state),
  shipmentItems: shipmentItemsSelector(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  replace,
  goBack,
  getShipmentAttempt,
  onShipmentProductsSortingChange: sorting => setShipmentProductsGridState('shipmentProductsSorting', sorting),
  deliverShipmentAttempt,
  deleteShipmentAttempt,
  resetShipmentState,
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Shipment)
