import {
  pipe, map, mapObjIndexed, range, values, join, reduceRight, reject,
  subtract, head, last, append, concat, flatten, isEmpty, filter, includes,
  isNil,
  sum, divide
} from 'ramda'

// const boxSizes = [1, 2, 3, 4, 6, 9, 12]

const boxesPulp = {
  1: 0.285,
  2: 0.373,
  3: 0.506,
  4: 0.762,
  5: 0.762,
  6: 0.762,
  7: 1.169,
  8: 1.169,
  9: 1.169,
  10: 1.269,
  11: 1.269,
  12: 1.269
}

// NOTE delivery charge
const perPallet = 55
const setsPerPallet1s = 960
const setsPerPallet2s = 480
const setsPerPallet3s = 352
const setsPerPallet6s = 176
const setsPerPallet9s = 132
const setsPerPallet12s = 88
const calculateInnersCost = (cost, sets) => sum([cost, divide(perPallet, sets)])
const boxesPulpInners = {
  1: calculateInnersCost(0.55, setsPerPallet1s),
  2: calculateInnersCost(1.10, setsPerPallet2s),
  3: calculateInnersCost(1.10, setsPerPallet3s),
  4: calculateInnersCost(1.59, setsPerPallet6s),
  5: calculateInnersCost(1.59, setsPerPallet6s),
  6: calculateInnersCost(1.59, setsPerPallet6s),
  7: calculateInnersCost(2.69, setsPerPallet9s),
  8: calculateInnersCost(2.69, setsPerPallet9s),
  9: calculateInnersCost(2.69, setsPerPallet9s),
  10: calculateInnersCost(3.18, setsPerPallet12s),
  11: calculateInnersCost(3.18, setsPerPallet12s),
  12: calculateInnersCost(3.18, setsPerPallet12s)
}

const boxesBudget = {
  // 1: null,
  // 2: null,
  // 3: null,
  4: 1.38,
  5: 1.34,
  6: 1.34,
  7: 2.50,
  8: 2.50,
  9: 2.50,
  10: 2.50,
  11: 2.50,
  12: 2.50
}

const calculatePackagingCost = (parcels, packaging = 'pulp') => {
  // console.log('calculatePackagingCost', parcels, packaging);
  let total = 0

  if (packaging === 'budget') {
    const budgets = pipe(
      map(size => {
        let cost = 0

        if (boxesBudget[size]) {
          cost = boxesBudget[size]
        } else {
          cost = sum([boxesPulp[size], boxesPulpInners[size]])
        }

        return cost
      }),
      sum
    )(parcels)
    // console.log('calculatePackagingCost BUDGET', budgets);

    total = budgets
    // return Number(Number.parseFloat(budgets).toFixed(2))
  } else if (packaging === 'pulp') {
    const pulps = pipe(
      map(size => {
        const cost = sum([boxesPulp[size], boxesPulpInners[size]])
        return cost
      }),
      sum
    )(parcels)
    // console.log('calculatePackagingCost PULPS', pulps);

    total = pulps
    // return Number(Number.parseFloat(pulps).toFixed(2))
  }

  return Number(Number.parseFloat(total).toFixed(2))



  // return [
  //   {
  //     type: 'pulp',
  //     // cost: Number.parseFloat(pulp).toFixed(2)
  //   },
  //   {
  //     type: 'budget',
  //     // cost: Number.parseFloat(budget).toFixed(2)
  //   }
  // ]
}

export {
  // boxSizes,
  boxesPulp,
  boxesPulpInners,
  boxesBudget,
  calculatePackagingCost
}
