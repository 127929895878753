import {
  pipe, map, mapObjIndexed, range, head, last, concat, flatten, isEmpty, values
} from 'ramda'

export const mapPostcodes = (postcodes) => {
  return pipe(
    mapObjIndexed((districts, area, obj) => {
      // console.log('AAA', area);
      let districtsRange = []
      const districtsCodes = pipe(
        map(
          district => {
            if (Array.isArray(district)) {
              const fromTo = range(head(district), last(district) + 1)
              districtsRange = concat(districtsRange, fromTo)
              return fromTo
            }
          }
        ),
        flatten,
        map(code => {
          return concat(area, String(code))
        })
      )(districts)
      // console.log('%c districtsRange ::', 'color: red', area, districtsRange);
      // console.log('- - - - - -');
      return isEmpty(districtsCodes) ? [area] : districtsCodes
      // return {
      //   codes: isEmpty(districtsCodes) ? [area] : districtsCodes,
      //   districts: districtsRange
      // }
    }),
    values,
    flatten
  )(postcodes)
}
