import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  equals, or, update, append, init, toLower, toUpper, path, length, join, isEmpty, mapObjIndexed, concat, contains, includes
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  shippingPricesZonesSelector,
  shippingPricesSelectedZoneSelector,
  shippingPricesSelector,
  shippingPricesCompareSelector
} from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { indigo, yellow, red, green, pink } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
// import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
// import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
// import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Grow from '@material-ui/core/Grow'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import IconSpeakerNotes from '@material-ui/icons/SpeakerNotes'
import IconSpeakerNotesOff from '@material-ui/icons/SpeakerNotesOff'

// React Grid
import {
  Grid as ReactGrid,
  Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

// Components
import Header from '../components/Header'
// import RefreshButton from '../components/RefreshButton'
// import Loading from '../components/Loading'
import GridCurrencyTypeProvider from '../components/GridCurrencyTypeProvider'

const AppSwitch = withStyles({
  switchBase: {
    color: pink[500],
    '& + $track': {
      backgroundColor: pink[500],
    },
    '&$checked': {
      color: green['A400'],
    },
    '&$checked + $track': {
      backgroundColor: green['A400'],
    },
  },
  checked: {},
  track: {},
})(Switch)

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  // divider: {
  //   marginBottom: theme.spacing(1),
  // },
  filter: {
    paddingTop: theme.spacing(0.5),
  },
  buttonNotes: {
    marginLeft: theme.spacing(1.5)
  },
  buttonNotesIconOn: {
    color: indigo['A100'],
    opacity: 0
  },
  buttonNotesIconOff: {
    position: 'absolute',
    opacity: 0
  },
  notes: {
    marginTop: theme.spacing(2),
  }
})


class ShippingCouriers extends Component {
  state = {
    showNotes: false
  }

  componentDidMount() {
    this.handleMount()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  // componentWillUnmount() {}

  handleMount = () => {
    // NOTE force re-render on mount (so drawer closes)
    this.props.setShippingPricesZone(this.props.shippingPricesZone)
  }

  toggleNotes = () => {
    this.setState({
      showNotes: !this.state.showNotes
    })
  }

  handleCourierChange = event => {
    this.props.setShippingPricesCourier(event.target.value)
  }

  handleZoneChange = event => {
    this.props.setShippingPricesZone(event.target.value)
  }

  handlePackagingChange = event => {
    const packaging = event.target.checked ? 'budget' : 'pulp'
    this.props.setShippingPackaging(packaging)
  }

  headerCellComponent = ({ style, ...cellProps }) => {
    const {
      packaging,
      shippingPricesCompare: { results }
    } = this.props
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // packaging
    if (
      (equals(columnName, 'pulp') && packaging === 'pulp'
      || equals(columnName, 'budget') && packaging === 'budget')
      && !isEmpty(results)
    ) {
      cellStyle = {
        ...cellStyle,
        color: yellow['A400']
      }
    }

    // final price inc VAT
    if (includes('incvat', toLower(columnName))) {
      cellStyle = {
        ...cellStyle,
        color: indigo['A100']
      }
    }

    return <TableHeaderRow.Cell style={cellStyle} {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const {
      packaging,
      shippingPricesCompare: { results }
    } = this.props
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    // if (contains(columnName, [
    //   'tracking', 'comment'
    // ])) {
    //   cellStyle = {
    //     ...cellStyle,
    //     whiteSpace: 'pre-line',
    //   }
    // }

    // packaging
    if (
      (equals(columnName, 'pulp') && packaging === 'pulp'
      || equals(columnName, 'budget') && packaging === 'budget')
      && !isEmpty(results)
    ) {
      cellStyle = {
        ...cellStyle,
        color: yellow['A400']
      }
    }

    // final price inc VAT
    if (includes('incvat', toLower(columnName))) {
      cellStyle = {
        ...cellStyle,
        color: indigo['A100']
      }
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = (colSpan, message) => {
    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{message}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      classes,
      couriers,
      packaging,
      shippingPrices,
      shippingPricesColumns,
      shippingPricesColumnExtensions,
      shippingPricesCourier,
      shippingPricesZones,
      shippingPricesZone,
      shippingPricesSelectedZone,
      shippingPricesCompare
    } = this.props
    const {
      showNotes
    } = this.state
    // console.log('uuuu', this.props);
    const { columns, results } = shippingPricesCompare
    const pricesColumns = concat(shippingPricesColumns, columns)
    // console.log('AAA', shippingPricesCompare);
    // console.log('BBB', pricesColumns);
    // console.log('CCC', results);

    return (
      <div>
        <Header
          title={'Shipping Prices'}
          subtitle={'to do..'}
        >
          {/* <RefreshButton
            onClick={this.handleMount}
          /> */}
        </Header>
        <Paper className={classes.paper}>
          <Grid container spacing={3} className={classes.filter}>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Courier</InputLabel>
                <Select
                  value={shippingPricesCourier}
                  onChange={this.handleCourierChange}
                  autoWidth
                  label="Courier"
                >
                  {couriers.map(courier => {
                    return (
                      <MenuItem
                        key={courier.acronym}
                        value={courier.name}
                      >
                        {courier.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Zone</InputLabel>
                <Select
                  value={shippingPricesZone}
                  onChange={this.handleZoneChange}
                  autoWidth
                  label="Zone"
                  disabled={!shippingPricesCourier}
                >
                  {shippingPricesZones.map(zone => {
                    return (
                      <MenuItem
                        key={zone.name}
                        value={zone.name}
                      >
                        {zone.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} sm={4} alignItems="center" wrap="nowrap">
              <Grid item xs>
                <FormControl variant="outlined" fullWidth>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>pulp</Grid>
                      <Grid item>
                        <AppSwitch
                          checked={packaging === 'budget'}
                          onChange={this.handlePackagingChange}
                          name="packaging"
                        />
                      </Grid>
                      <Grid item>budget</Grid>
                    </Grid>
                  </Typography>
                </FormControl>
              </Grid>
              {shippingPricesSelectedZone && (
                <Grid item>
                  <Tooltip
                    title={showNotes ? 'Hide notes' : 'Show notes'}
                    placement="left"
                  >
                    <IconButton
                      className={classes.buttonNotes}
                      onClick={this.toggleNotes}
                    >
                      <Grow in={showNotes}>
                        <IconSpeakerNotes className={classes.buttonNotesIconOn} />
                      </Grow>
                      <Grow in={!showNotes}>
                        <IconSpeakerNotesOff className={classes.buttonNotesIconOff} />
                      </Grow>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          {shippingPricesSelectedZone && (
            <Collapse in={showNotes}>
              <div className={classes.notes}>
                <Typography className={classes.serviceInfo} variant="body1">
                  {`Postcodes include: ${JSON.stringify(shippingPricesSelectedZone.postcodesInclude, null, 2)}`}
                </Typography>
                <Typography className={classes.serviceInfo} variant="body1">
                  {`Postcodes exclude: ${JSON.stringify(shippingPricesSelectedZone.postcodesExclude, null, 2)}`}
                </Typography>
              </div>
            </Collapse>
          )}
        </Paper>
        <Paper className={classes.paper}>
          <ReactGrid
            rows={shippingPrices}
            // rows={results}
            columns={shippingPricesColumns.asMutable()}
            // columns={pricesColumns.asMutable()}
          >
            <GridCurrencyTypeProvider
              columns={[
                'pulp', 'budget',
                'postage', 'totalExVat', 'totalIncVat',
                'dpdPostage', 'dpdExVat', 'dpdIncVat',
                'parcelforcePostage', 'parcelforceExVat', 'parcelforceIncVat'
              ]}
            />
            <Table
              columnExtensions={shippingPricesColumnExtensions.asMutable()}
              rowComponent={this.rowComponent}
              cellComponent={this.cellComponent}
              noDataCellComponent={() =>
                this.cellComponentNoData(
                  length(pricesColumns),
                  'No prices.'
                )
              }
            />
            <TableHeaderRow
              cellComponent={this.headerCellComponent}
            />
          </ReactGrid>
        </Paper>
      </div>
    )
  }
}

ShippingCouriers.propTypes = {
  classes: PropTypes.object.isRequired,
  couriers: PropTypes.array.isRequired,
  packaging: PropTypes.string.isRequired,
  shippingPricesColumns: PropTypes.array.isRequired,
  shippingPricesColumnExtensions: PropTypes.array.isRequired,
  shippingPricesCourier: PropTypes.string.isRequired,
  shippingPricesZone: PropTypes.string.isRequired,
  shippingPricesZones: PropTypes.array.isRequired,
  shippingPricesSelectedZone: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    () => null
  ]),
  shippingPrices: PropTypes.array.isRequired,
  setShippingPricesCourier: PropTypes.func.isRequired,
  setShippingPricesZone: PropTypes.func.isRequired,
  setShippingPackaging: PropTypes.func.isRequired
}

const {
  setShippingPricesCourier,
  setShippingPricesZone,
  setShippingPackaging
} = Actions

const mapStateToProps = state => ({
  ...state.shipping,
  shippingPricesZones: shippingPricesZonesSelector(state),
  shippingPricesSelectedZone: shippingPricesSelectedZoneSelector(state),
  shippingPrices: shippingPricesSelector(state),
  shippingPricesCompare: shippingPricesCompareSelector(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  setShippingPricesCourier,
  setShippingPricesZone,
  setShippingPackaging
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(ShippingCouriers)
