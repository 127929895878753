import dpd from './shipping-dpd.js'
import parcelforce from './shipping-parcelforce.js'
import packfleet from './shipping-packfleet.js'

// Scottish Highlands and Islands
// IV
// FK17-21
// HS
// KA27-28
// KW
// PA20-49
// PA60-78
// PH19-26
// PH30-44
// PH49-50
// ZE

export default [
  dpd,
  parcelforce,
  packfleet
]
