import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  // Notification
  setNotification: ['message', 'level'],
  clearNotification: null,
  // Config
  getConfigAttempt: null,
  getConfigSuccess: ['data'],
  getConfigFailure: ['error'],
  // Auth & User
  loginAttempt: ['user'],
  loginAuthSuccess: ['auth'],
  loginUserSuccess: ['user', 'success'],
  loginFailure: ['error'],
  logout: null,
  getUsersAttempt: null,
  getUsersSuccess: ['data'],
  getUsersFailure: null,
  forgotPasswordAttempt: ['email'],
  forgotPasswordSuccess: ['user', 'success'],
  forgotPasswordFailure: ['error'],
  resetPasswordAttempt: ['data'],
  resetPasswordSuccess: ['data', 'success'],
  resetPasswordFailure: ['error'],
  setGoogleToken: ['token'],
  // Dashboard
  getOrdersReadyToShipAttempt: ['params'],
  getOrdersReadyToShipSuccess: ['data'],
  getOrdersReadyToShipFailure: null,
  getOrdersDatedDeliveryAttempt: null,
  getOrdersDatedDeliverySuccess: ['data'],
  getOrdersDatedDeliveryFailure: null,
  getShipmentsActiveAttempt: null,
  getShipmentsActiveSuccess: ['data'],
  getShipmentsActiveFailure: ['error'],
  // Orders
  getOrderStatusesAttempt: null,
  getOrderStatusesSuccess: ['data'],
  getOrderStatusesFailure: null,
  watchOrdersFilter: ['params'],
  getOrdersAttempt: ['params'],
  getOrdersSuccess: ['data', 'success'],
  getOrdersFailure: ['error', 'problem', 'status'],
  setOrdersGridState: ['name', 'value'],
  changeOrdersStatusAttempt: ['orders', 'orderStatusId', 'comment'],
  changeOrderStatusResult: ['orderReference', 'orderStatusId', 'statusChanged'],
  changeOrdersStatusSuccess: ['success'],
  changeOrdersStatusFailure: ['error'],
  changeOrdersStatusTerminate: null,
  // Order
  getOrderAttempt: ['orderReference'],
  getOrderSuccess: ['data', 'shipments', 'history', 'success'],
  getOrderFailure: ['error', 'problem', 'status'],
  setOrderGridState: ['name', 'value'],
  changeOrderStatusAttempt: ['orderReference', 'change'],
  changeOrderStatusSuccess: ['orderStatusId', 'history', 'success'],
  changeOrderStatusFailure: ['error', 'problem', 'status'],
  adjustOrderItemsStockAttempt: ['orderReference', 'products', 'deallocate'],
  adjustOrderItemsStockSuccess: ['data', 'history', 'success'],
  adjustOrderItemsStockFailure: ['error', 'problem', 'status', 'errors'],
  shipOrderAttempt: ['courier', 'shipment'],
  shipOrderSuccess: ['data', 'shipment', 'history', 'success'],
  shipOrderFailure: ['error', 'problem', 'status', 'errors'],
  orderShipped: ['orderReference'],
  getOrderShipmentLabelsAttempt: ['courier', 'shipmentId'],
  getOrderShipmentLabelsSuccess: ['success'],
  getOrderShipmentLabelsFailure: ['error', 'problem', 'status'],
  getOrderShipmentDocumentsAttempt: ['courier', 'shipmentId'],
  getOrderShipmentDocumentsSuccess: ['success'],
  getOrderShipmentDocumentsFailure: ['error', 'problem', 'status'],
  syncOrderAttempt: ['orderId'],
  syncOrderSuccess: ['success'],
  syncOrderFailure: ['error', 'problem', 'status'],
  deleteOrderAttempt: ['orderId', 'skus'],
  deleteOrderSuccess: ['success'],
  deleteOrderFailure: ['error', 'problem', 'status'],
  resetOrderState: null,
  // Couriers
  getCouriersAttempt: null,
  getCouriersSuccess: ['data'],
  getCouriersFailure: null,
  getCourierServicesAttempt: ['courier', 'shipment'],
  getCourierServicesSuccess: ['courier', 'data', 'collections'],
  getCourierServicesFailure: ['error', 'problem', 'status', 'errors'],
  resetCourierServices: null,
  // Shipments
  watchShipmentsFilter: ['params'],
  getShipmentsAttempt: ['params'],
  getShipmentsSuccess: ['data', 'success'],
  getShipmentsFailure: ['error', 'problem', 'status'],
  setShipmentsGridState: ['name', 'value'],
  // Shipment
  getShipmentAttempt: ['shipmentId'],
  getShipmentSuccess: ['data', 'success'],
  getShipmentFailure: ['error', 'problem', 'status'],
  setShipmentProductsGridState: ['name', 'value'],
  deliverShipmentAttempt: ['shipmentId'],
  deliverShipmentSuccess: ['data', 'success'],
  deliverShipmentFailure: ['error', 'problem', 'status'],
  deleteShipmentAttempt: ['courier', 'shipmentId', 'data'],
  deleteShipmentSuccess: ['success'],
  deleteShipmentFailure: ['error', 'problem', 'status'],
  resetShipmentState: null,
  // Products
  watchProductsFilter: ['params'],
  getProductsAttempt: ['params'],
  getProductsSuccess: ['data', 'success'],
  getProductsFailure: ['error', 'problem', 'status'],
  setProductsGridState: ['name', 'value'],
  // Product
  getProductAttempt: ['skuCode'],
  getProductSuccess: ['data', 'stockAdjustments', 'success'],
  getProductFailure: ['error', 'problem', 'status'],
  setStockAdjustmentsGridState: ['name', 'value'],
  resetProductState: null,
  updateProductAttempt: ['skuCode', 'product'],
  updateProductSuccess: ['data', 'success'],
  updateProductFailure: ['error', 'problem', 'status', 'errors'],
  // Product Stock
  adjustStockAttempt: ['skuCode', 'adjustment', 'reserve'],
  adjustStockSuccess: ['data', 'stockAdjustments', 'success'],
  adjustStockFailure: ['error', 'problem', 'status', 'errors'],
  unreserveStockAttempt: ['skuCode', 'adjustment'],
  unreserveStockSuccess: ['data', 'stockAdjustments', 'success'],
  unreserveStockFailure: ['error', 'problem', 'status', 'errors'],
  deallocateProductAttempt: ['orderId', 'product'],
  deallocateProductSuccess: ['data', 'stockAdjustments', 'success'],
  deallocateProductFailure: ['error', 'problem', 'status'],
  // Suppliers
  getSuppliersAttempt: ['startup'],
  getSuppliersSuccess: ['data', 'success'],
  getSuppliersFailure: ['error', 'problem', 'status'],
  setSuppliersGridState: ['name', 'value'],
  setSupplierId: ['supplierId'],
  addSupplier: ['supplier'],
  updateSupplierAttempt: ['supplierId', 'supplier'],
  updateSupplierSuccess: ['data', 'success'],
  updateSupplierFailure: ['error', 'problem', 'status'],
  // Deliveries
  watchDeliveriesFilter: ['params'],
  getDeliveriesAttempt: ['params'],
  getDeliveriesSuccess: ['data', 'success'],
  getDeliveriesFailure: ['error', 'problem', 'status'],
  setDeliveriesGridState: ['name', 'value'],
  // Delivery
  getDeliveryAttempt: ['supplierId', 'deliveryId'],
  getDeliverySuccess: ['data', 'success'],
  getDeliveryFailure: ['error', 'problem', 'status'],
  setDeliveryGridState: ['name', 'value'],
  saveDeliveryAttempt: ['supplierId', 'deliveryId', 'expectedDate', 'comments', 'products'],
  saveDeliverySuccess: ['data', 'success'],
  saveDeliveryFailure: ['error', 'problem', 'status'],
  completeDeliveryAttempt: ['supplierId', 'deliveryId', 'comments'],
  completeDeliverySuccess: ['data', 'success'],
  completeDeliveryFailure: ['error', 'problem', 'status'],
  setInvoice: ['invoice'],
  setInvoiceProducts: ['products'],
  setInvoiceProductCost: ['productId', 'cost'],
  saveDeliveryInvoiceAttempt: ['deliveryId', 'invoice', 'products'],
  saveDeliveryInvoiceSuccess: ['data', 'success'],
  saveDeliveryInvoiceFailure: ['error', 'problem', 'status'],
  deleteDeliveryAttempt: ['deliveryId'],
  deleteDeliverySuccess: ['success'],
  deleteDeliveryFailure: ['error', 'problem', 'status'],
  resetDeliveryState: null,
  // Restock
  getRestockAttempt: null,
  getRestockSuccess: ['data', 'success'],
  getRestockFailure: ['error', 'problem', 'status'],
  setRestockGridState: ['name', 'value'],
  // Jobs
  getJobsAttempt: null,
  getJobsSuccess: ['data', 'success'],
  getJobsFailure: ['error', 'problem', 'status'],
  retryFailedJobAttempt: ['failedJobUuid'],
  retryFailedJobSuccess: ['failedJobUuid', 'success'],
  retryFailedJobFailure: ['error', 'problem', 'status'],
  deleteFailedJobAttempt: ['failedJobUuid'],
  deleteFailedJobSuccess: ['failedJobUuid', 'success'],
  deleteFailedJobFailure: ['error', 'problem', 'status'],
  addJob: ['job'],
  removeJob: ['jobId', 'lastJobProcessedDate'],
  addFailedJobs: ['jobId', 'failedJobs'],
  setJobsGridState: ['name', 'value'],
  // Shipping
  setShippingPostcode: ['postcode'],
  setShippingParcels: ['parcels'],
  setShippingPricesCourier: ['courier'],
  setShippingPricesZone: ['zone'],
  setShippingPackaging: ['packaging'],
  resetShippingState: null
})
