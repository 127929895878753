import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  prop, propOr, path, pathOr, equals, or, length, takeLast, isEmpty,
  pipe, pluck, sum, map, multiply, head, replace, concat, props, contains,
  curry, when, propEq, assoc, and, defaultTo, assocPath, all, without,
  sortBy, toLower, filter, reject, find, pick, join, flatten,
  // TODO need helper for selection
  times, identity,
} from 'ramda'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { goBack, replace as routerReplace } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { Creators as Actions } from '../actions'
import {
  nl2brArray,
  isGridColumnSortingApplied,
  findById,
  sortDecimal,
  naturalSort,
  searchInString,
  groupSumBy,
} from '../util/helpers'
import {
  deliveryRestockProductsSelector,
  deliveryOtherProductsSelector,
  supplierSelector,
} from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { yellow } from '@material-ui/core/colors'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import IconPrint from '@material-ui/icons/Print'
import IconCloudDownload from '@material-ui/icons/CloudDownload'
import IconWhatshot from '@material-ui/icons/Whatshot'
import IconWarning from '@material-ui/icons/Warning'
import IconEdit from '@material-ui/icons/Edit'
import IconVisibility from '@material-ui/icons/Visibility'
import IconDelete from '@material-ui/icons/Delete'
import IconSync from '@material-ui/icons/SyncAlt'
import IconLaunch from '@material-ui/icons/Launch'
import IconCloudUpload from '@material-ui/icons/CloudUpload'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// React Grid
import {
  SortingState, FilteringState, SelectionState, PagingState, RowDetailState,
  IntegratedSorting, IntegratedFiltering, IntegratedSelection, IntegratedPaging,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableSelection, TableHeaderRow, TableFilterRow, TableRowDetail,
  PagingPanel, TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import { CSVLink } from 'react-csv'
import Header from '../components/Header'
import GoBackButton from '../components/GoBackButton'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridColumnChooserCell from '../components/GridColumnChooserCell'
import GridCurrencyTypeProvider from '../components/GridCurrencyTypeProvider'
import GridDateTypeProvider from '../components/GridDateTypeProvider'
import DialogSupplierUpdate from '../components/DialogSupplierUpdate'
import DialogInvoiceParser from '../components/DialogInvoiceParser'
import PrintButton from '../components/PrintButton'
import PrintWrapper from '../components/PrintWrapper'
import PrintDelivery from '../components/PrintDelivery'
import GoogleDrivePicker from '../components/GoogleDrivePicker'

const supportsGoogleDrive = !!process.env.REACT_APP_GOOGLE_DRIVE_API_KEY
  && !!process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID
  && !!process.env.REACT_APP_GOOGLE_DRIVE_PARENT_ID

const descSortingColumns = [
  'cost_ex_vat', 'total_cost_ex_vat', 'supplier_case_size', 'total_ordered',
  'total_allocated', 'total_shipped', 'items_in_stock',
  'total_notified', 'items_required', 'restock',
]

const isRestockTab = tab => tab === 1

const isOtherTab = tab => tab === 2

const isInvoiceTab = tab => tab === 3

const filterSelected = (data, selection) => map(
  index => data[index],
  selection
)

const alterProducts = curry((data, skuCode, items) => map(
  when(
    propEq('sku', skuCode),
    assoc('restock', items),
  ),
  data
))

const getCsvData = data => {
  const sortedData = sortBy(compose(toLower, prop('description')))(data)
  return concat(
    [['SKU', 'Supplier Code', 'Description', 'Quantity']],
    map(props(['sku', 'supplier_code', 'description', 'restock']), sortedData),
  )
}

const getTotalItems = data => pipe(
  pluck('restock'),
  sum,
  defaultTo(0)
)(data)

const getTotalCost = data => pipe(
  map(
    product => multiply(prop('restock', product), prop('cost_ex_vat', product))
  ),
  reject(cost => !Number(cost)),
  sum,
  defaultTo('0.00')
)(data)

const getTotalDeliveryCost = data => pipe(
  pluck('total_cost_ex_vat'),
  reject(cost => !Number(cost)),
  sum,
  defaultTo('0.00')
)(data)

const getTotalInvoiceCost = data => pipe(
  pluck('invoice_cost_ex_vat'),
  reject(cost => !Number(cost)),
  sum,
  defaultTo('0.00')
)(data)

const invoiceParsers = [
  {
    name: 'ABS Wine',
    notes: [
      'Does not have product codes.',
      '2 page invoice not tested.'
    ],
    startAfter: 'Total',
    stopBefore: ['Total wines:'],
    columns: 18,
    columnCode: null,
    columnName: 3,
    columnCost: 18
  },
  {
    name: 'Alliance Wine',
    notes: ['2 page invoice not tested.'],
    startAfter: 'code',
    stopBefore: ['Subtotal'],
    columns: 15,
    columnCode: 1,
    columnName: 3,
    columnCost: 13
  },
  {
    name: 'Bancroft Wines',
    notes: ['May need to fix chunks.'],
    startAfter: 86,
    stopBefore: [],
    columns: 13,
    columnCode: 5,
    columnName: 7,
    columnCost: 1
  },
  {
    name: 'Berkmann',
    notes: [
      'May need to change columns. (eg 13 or 15)',
      'May need to fix chunks.'
    ],
    startAfter: 'Amount',
    stopBefore: ['Total', 'Payment', 'Subject'],
    columns: 13,
    columnCode: 1,
    columnName: 3,
    columnCost: 13
  },
  // {
  //   name: 'Bibendum',
  //   notes: ['Should work as expected.'],
  //   startAfter: 'VAT No',
  //   stopBefore: ['GBP', 'C/FWD'],
  //   columns: 11,
  //   columnCode: 1,
  //   columnName: 3,
  //   columnCost: 5,
  //   codeFunction: code => parseInt(code, 10).toString()
  // },
  {
    name: 'Corney & Barrow',
    notes: ['Should work as expected.'],
    startAfter: 'Value',
    stopBefore: ['Pounds Sterling', 'If this account has not already been paid'],
    columns: 16,
    columnCode: 1,
    columnName: 2,
    columnCost: 16
  },
  {
    name: 'Enotria',
    notes: ['Should work as expected.'],
    startAfter: 'Amount',
    stopBefore: ['Total', 'Please'],
    columns: 11,
    columnCode: 1,
    columnName: 3,
    columnCost: 11
  },
  {
    name: 'Flint Wines',
    notes: ['2 page invoice not tested.'],
    startAfter: 'Amount',
    stopBefore: ['Total', 'Payment'],
    columns: 13,
    columnCode: 1,
    columnName: 3,
    columnCost: 13
  },
  {
    name: 'Hallgarten',
    notes: ['2 page invoice not tested.'],
    startAfter: 'Value',
    stopBefore: ['Total', 'Hallgarten'],
    columns: 11,
    columnCode: 1,
    columnName: 3,
    columnCost: 11
  },
  {
    name: 'Hatch Mansfield',
    notes: ['Will need to fix chunks.'],
    startAfter: 76,
    stopBefore: ['Total Cases', 'Registration'],
    columns: 11,
    columnCode: 1,
    columnName: 3,
    columnCost: 9
  },
  {
    name: 'Jascots',
    notes: ['Will need to fix chunks.'],
    startAfter: 48,
    stopBefore: [],
    columns: 13,
    columnCode: 1,
    columnName: 3,
    columnCost: 11
  },
  {
    name: 'John Fells',
    notes: ['Will need to fix chunks.'],
    startAfter: 92,
    stopBefore: ['Total', 'All goods'],
    columns: 11,
    columnCode: 1,
    columnName: 3,
    columnCost: 11
  },
  {
    name: 'Justerini & Brooks',
    notes: ['2 page invoice not tested.'],
    startAfter: 'Amount',
    stopBefore: ['Total'],
    columns: 11,
    columnCode: 1,
    columnName: 3,
    columnCost: 11
  },
  {
    name: 'Lebanese Wines',
    notes: [
      'Does not have product codes.',
      '2 page invoice not tested.'
    ],
    startAfter: 'Total Price',
    stopBefore: ['Bank'],
    columns: 7,
    columnCode: null,
    columnName: 1,
    columnCost: 7
  },
  {
    name: 'Les Caves de Pyrene',
    notes: ['Should work as expected.'],
    startAfter: 75,
    stopBefore: [],
    columns: 12,
    columnCode: 3,
    columnName: 5,
    columnCost: 1
  },
  {
    name: 'Liberty',
    notes: ['May need to fix chunks.'],
    startAfter: 'Amount',
    stopBefore: ['Total', 'Liberty'],
    columns: 15,
    columnCode: 1,
    columnName: 3,
    columnCost: 15
  },
  {
    name: 'Maisons Marques et Domaines',
    notes: ['2 page invoice not tested.'],
    startAfter: 'VAT Code',
    stopBefore: ['Total'],
    columns: 15,
    columnCode: 1,
    columnName: 3,
    columnCost: 13
  },
  // {
  //   name: 'Matthew Clark',
  //   notes: ['Should work as expected.'],
  //   startAfter: 'VAT No',
  //   stopBefore: ['GBP', 'C/FWD'],
  //   columns: 11,
  //   columnCode: 1,
  //   columnName: 3,
  //   columnCost: 5,
  //   codeFunction: code => parseInt(code, 10).toString()
  // },
  {
    name: 'New Generation',
    notes: ['2 page invoice not tested.'],
    startAfter: 'Duty Paid',
    stopBefore: [],
    columns: 12,
    columnCode: 3,
    columnName: 5,
    columnCost: 1
  },
  {
    name: 'North South Wines',
    notes: ['2 page invoice not tested.'],
    startAfter: 'Amount',
    stopBefore: ['Total'],
    columns: 11,
    columnCode: 1,
    columnName: 3,
    columnCost: 11
  },
  {
    name: 'Seckford',
    notes: ['2 page invoice not tested.'],
    startAfter: 51,
    stopBefore: ['RETENTION'],
    columns: 13,
    columnCode: 1,
    columnName: 3,
    columnCost: 13
  },
  {
    name: 'Waddesdon',
    notes: ['2 page invoice not tested.'],
    startAfter: 'Net',
    stopBefore: ['BANK'],
    columns: 15,
    columnCode: 3,
    columnName: 5,
    columnCost: 15
  },
]

const getInvoiceParser = name => pipe(
  find(propEq('name', name)),
  defaultTo(null)
)(invoiceParsers)

const styles = theme => ({
  comment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  commentText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  expectedDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(1),
    textAlign: 'right',
  },
  invoice: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  invoiceHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  invoiceHeading: {
    display: 'flex',
    alignItems: 'center',
  },
  invoiceHeadingText: {
    marginLeft: 'auto',
  },
  invoiceHeadingButton: {
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
  },
  invoiceInput: {
    width: 80,
  },
  rowDetails: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 0,
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: 0,
    }
  },
  itemIcon: {
    marginRight: theme.spacing(0.5),
  },
  itemButton: {
    padding: '2px 6px',
    marginRight: theme.spacing(0.5),
  },
  itemInfo: {
    fontSize: '0.6rem',
    marginTop: theme.spacing(0.5),
  },
  itemInfoLink: {
    marginRight: theme.spacing(0.5),
  },
  checkbox: {
    marginRight: 0,
  },
  rowWarning: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWarningIcon: {
    verticalAlign: 'inherit',
    marginLeft: theme.spacing(0.5),
    fontSize: 22,
  },
  warningTooltip: {
    backgroundColor: yellow['A400'],
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: yellow['A400'],
  },
  success: {
    color: theme.palette.success.main,
  }
})

class Delivery extends Component {
  state = {
    supplierId: Number(pathOr(0, ['params', 'supplierId'], this.props.match)),
    deliveryId: Number(pathOr(0, ['params', 'deliveryId'], this.props.match)),
    expectedDate: moment().add(moment().day() === 5 ? 3 : 1, 'days').format('YYYY-MM-DD'),
    comments: '',
    tab: 1,
    restockProducts: [],
    restockProductsSelection: [],
    otherProducts: [],
    otherProductsSelection: [],
    expandedRowId: undefined,
    replacing: false,
    replacingRowId: null,
    saved: true,
    csvData: [],
    totalItems: 0,
    totalCost: 0.00,
    editingSupplier: false,
    deleting: false,
    invoicePdfText: [],
    invoiceUnmatched: [],
    editingInvoiceParser: false,
  }

  componentDidMount() {
    this.handleMount()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Delivery retrieved/updated?
    if (
      !equals(this.props.data, nextProps.data)
      || !equals(this.props.restockProducts, nextProps.restockProducts)
      || !equals(this.props.otherProducts, nextProps.otherProducts)
    ) {
      const { supplierId, deliveryId, expectedDate, comments } = this.state
      const newDeliveryId = path(['data', 'id'], nextProps)
      const selectedRestockProducts = filterSelected(nextProps.restockProducts, nextProps.restockProductsSelection)
      const newExpectedDate = path(['data', 'expected_at'], nextProps)
      const newComments = path(['data', 'comments'], nextProps)

      this.setState({
        deliveryId: newDeliveryId || deliveryId,
        expectedDate: newExpectedDate || expectedDate,
        comments: newComments || comments,
        restockProducts: nextProps.restockProducts,
        restockProductsSelection: nextProps.restockProductsSelection.asMutable({ deep: true }),
        otherProducts: nextProps.otherProducts,
        // otherProductsSelection: [],
        saved: true,
        csvData: getCsvData(selectedRestockProducts),
        totalItems: getTotalItems(selectedRestockProducts),
        totalCost: getTotalCost(selectedRestockProducts),
      }, () => {
        // replace url with new delivery id
        if (newDeliveryId !== undefined && newDeliveryId !== deliveryId) {
          this.props.routerReplace(`/delivery/${supplierId}/${newDeliveryId}`)
        }
      })
    }

    // Supplier updated?
    if (
      this.props.uiLoadingUpdateSupplier !== nextProps.uiLoadingUpdateSupplier
      && !nextProps.uiLoadingUpdateSupplier
      && !nextProps.errorSupplier
    ) {
      this.toggleEditingSupplierClose()
    }

    // Delivery deleted?
    if (
      !equals(this.props.data, nextProps.data)
      && !path(['data', 'id'], nextProps)
    ) {
      this.props.routerReplace('/deliveries')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  componentWillUnmount() {
    this.props.resetDeliveryState()
  }

  handleMount = () => {
    const { supplierId, deliveryId } = this.state
    this.props.getDeliveryAttempt(supplierId, deliveryId)
  }

  toggleDeleting = () => {
    this.setState({
      deleting: !this.state.deleting,
    })
  }

  toggleEditingSupplierOpen = supplierId => {
    this.props.setSupplierId(supplierId)
    this.setState({
      editingSupplier: true,
    })
  }

  toggleEditingSupplierClose = () => {
    this.props.setSupplierId(0)
    this.setState({
      editingSupplier: false,
    })
  }

  toggleInvoiceParser = () => {
    this.setState({
      editingInvoiceParser: !this.state.editingInvoiceParser,
    })
  }

  changeExpandedRows = rows => {
    // NOTE Expanding only 1 row at the time
    this.setState({ expandedRowId: head(takeLast(1, rows)) })
  }

  handleTabsChange = (event, value) => {
    this.changeExpandedRows([])
    this.setState({
      tab: value,
      replacing: false,
      replacingRowId: null
    })
  }

  handleGridRestockProductsSorting = sort => {
    const {
      restockProductsSorting,
      onProductsSortingChange,
    } = this.props
    const { columnName } = sort[0]

    // Descending as default direction
    if (
      contains(columnName, descSortingColumns)
      && !isGridColumnSortingApplied(columnName, restockProductsSorting)
    ) {
      sort[0].direction = 'desc';
    }

    onProductsSortingChange(sort)
  }

  handleGridOtherProductsSorting = sort => {
    const {
      otherProductsSorting,
      onOtherProductsSortingChange,
    } = this.props
    const { columnName } = sort[0]

    // Descending as default direction
    if (
      contains(columnName, descSortingColumns)
      && !isGridColumnSortingApplied(columnName, otherProductsSorting)
    ) {
      sort[0].direction = 'desc';
    }

    onOtherProductsSortingChange(sort)
  }

  handleExpectedDateChange = event => {
    const { restockProducts, restockProductsSelection } = this.props
    const expectedDate = path(['data', 'expected_at'], this.props)
    // const comments = path(['data', 'comments'], this.props)

    this.setState({
      expectedDate: event.target.value,
      saved: all(equals(true))([
        equals(expectedDate, event.target.value),
        // equals(comments, this.state.comments),
        equals(restockProducts, this.state.restockProducts),
        equals(restockProductsSelection, this.state.restockProductsSelection)
      ]),
    })
  }

  handleDeliveryCommentsChange = event => {
    this.setState({
      comments: event.target.value
    })
  }

  handleRestockProductsChange = (skuCode, items) => {
    const { restockProducts, restockProductsSelection } = this.props
    const expectedDate = path(['data', 'expected_at'], this.props)
    // const comments = path(['data', 'comments'], this.props)
    const alteredProducts = alterProducts(this.state.restockProducts, skuCode, items)
    const selectedRestockProducts = filterSelected(alteredProducts, this.state.restockProductsSelection)

    this.setState({
      restockProducts: alteredProducts,
      saved: all(equals(true))([
        equals(expectedDate, this.state.expectedDate),
        // equals(comments, this.state.comments),
        equals(restockProducts, alteredProducts),
        equals(restockProductsSelection, this.state.restockProductsSelection)
      ]),
      csvData: getCsvData(selectedRestockProducts),
      totalItems: getTotalItems(selectedRestockProducts),
      totalCost: getTotalCost(selectedRestockProducts),
    })
  }

  handleOtherProductsChange = (skuCode, items) => {
    const alteredProducts = alterProducts(this.state.otherProducts, skuCode, items)

    this.setState({
      otherProducts: alteredProducts,
    })
  }

  handleGridRestockProductsSelection = selection => {
    selection.sort()
    const { restockProducts, restockProductsSelection } = this.props
    const expectedDate = path(['data', 'expected_at'], this.props)
    // const comments = path(['data', 'comments'], this.props)
    const selectedRestockProducts = filterSelected(this.state.restockProducts, selection)

    this.setState({
      restockProductsSelection: selection,
      saved: all(equals(true))([
        equals(expectedDate, this.state.expectedDate),
        // equals(comments, this.state.comments),
        equals(restockProducts, this.state.restockProducts),
        equals(restockProductsSelection, selection)
      ]),
      csvData: getCsvData(selectedRestockProducts),
      totalItems: getTotalItems(selectedRestockProducts),
      totalCost: getTotalCost(selectedRestockProducts),
    })
  }

  handleGridOtherProductsSelection = selection => {
    selection.sort()
    this.setState({
      otherProductsSelection: selection,
    })
  }

  handleGridRestockProductsOrdersSelection = selection => {
    selection.sort()
    const { restockProducts, restockProductsSelection } = this.props
    const expectedDate = path(['data', 'expected_at'], this.props)
    // const comments = path(['data', 'comments'], this.props)
    const { expandedRowId } = this.state
    const alteredProducts = assocPath(
      [expandedRowId, 'ordersSelection'],
      selection,
      this.state.restockProducts,
    )

    this.setState({
      restockProducts: alteredProducts,
      saved: all(equals(true))([
        equals(expectedDate, this.state.expectedDate),
        // equals(comments, this.state.comments),
        equals(restockProducts, alteredProducts),
        equals(restockProductsSelection, this.state.restockProductsSelection)
      ]),
    })
  }

  handleGridOtherProductsOrdersSelection = selection => {
    selection.sort()
    const { otherProducts, expandedRowId } = this.state
    const alteredProducts = assocPath(
      [expandedRowId, 'ordersSelection'],
      selection,
      otherProducts,
    )

    this.setState({
      otherProducts: alteredProducts,
    })
  }

  handleUpdateSupplierSubmit = supplier => {
    const { updateSupplierAttempt } = this.props
    const { supplierId, name, email, comment } = supplier

    // TODO redux forms ???
    updateSupplierAttempt(supplierId, { name, email, comment })
  }

  handleSaveSubmit = () => {
    const { saveDeliveryAttempt } = this.props
    const { supplierId, deliveryId, expectedDate, comments, restockProducts, restockProductsSelection } = this.state
    const selectedRestockProducts = filterSelected(restockProducts, restockProductsSelection)
    const alteredProducts = map(
      product => ({
        product_id: product.id,
        restock: product.restock,
        orders: map(
          order => ({
            order_id: order.id,
            order_item_id: order.order_item_id,
          }),
          filterSelected(product.orders, product.ordersSelection)
        ),
        reserve: map(
          reserve => {
            let data = {
              order_reference: reserve.order_reference,
              quantity: reserve.quantity
            }
            if (reserve.kit_id) data.kit_id = reserve.kit_id
            return data
          },
          product.reserves || []
        )
      }),
      selectedRestockProducts
    )

    saveDeliveryAttempt(supplierId, deliveryId, expectedDate, comments, alteredProducts)
  }

  handleCompleteSubmit = () => {
    const { completeDeliveryAttempt } = this.props
    const { supplierId, deliveryId, comments } = this.state
    completeDeliveryAttempt(supplierId, deliveryId, comments)
  }

  handleReplacing = rowId => {
    this.changeExpandedRows([])
    this.setState({
      tab: 2,
      replacing: true,
      replacingRowId: rowId
    })
  }

  handleReplace = rowId => {
    const selectedProduct = head(filterSelected(this.state.otherProducts, [rowId]))
    const unselectedOtherProducts = without([selectedProduct], this.state.otherProducts)
    const replacingProduct = head(filterSelected(this.state.restockProducts, [this.state.replacingRowId]))
    let alteredRestockProducts = this.state.restockProducts
    const reserves = map(order => ({
      order_reference: order.order_reference,
      kit_id: pathOr(null, ['kit', 'id'], order),
      quantity: order.notified || order.ordered
    }), filterSelected(replacingProduct.orders, replacingProduct.ordersSelection))
    alteredRestockProducts[this.state.replacingRowId] = {
      ...selectedProduct,
      restock: replacingProduct.restock,
      reserves: reserves,
    }

    this.setState({
      tab: 1,
      replacing: false,
      replacingRowId: null,
      restockProducts: alteredRestockProducts,
      restockProductsSelection: times(identity, length(alteredRestockProducts)),
      otherProducts: unselectedOtherProducts,
      otherProductsSelection: [],
      expandedRowId: undefined,
      saved: false,
      csvData: getCsvData(alteredRestockProducts),
      totalItems: getTotalItems(alteredRestockProducts),
      totalCost: getTotalCost(alteredRestockProducts),
    })
  }

  handleAddToRestock = () => {
    const selectedOtherProducts = filterSelected(this.state.otherProducts, this.state.otherProductsSelection)
    const unselectedOtherProducts = without(selectedOtherProducts, this.state.otherProducts)
    const alteredRestockProducts = concat(selectedOtherProducts, this.state.restockProducts)

    this.setState({
      tab: 1,
      restockProducts: alteredRestockProducts,
      restockProductsSelection: times(identity, length(alteredRestockProducts)),
      otherProducts: unselectedOtherProducts,
      otherProductsSelection: [],
      expandedRowId: undefined,
      saved: false,
      csvData: getCsvData(alteredRestockProducts),
      totalItems: getTotalItems(alteredRestockProducts),
      totalCost: getTotalCost(alteredRestockProducts),
    })
  }

  handleDeleteDeliverySubmit = () => {
    const { deleteDeliveryAttempt } = this.props
    const { deliveryId } = this.state
    this.toggleDeleting()
    deleteDeliveryAttempt(deliveryId)
  }

  handleInvoiceCommentsChange = event => {
    const {
      invoice,
      setInvoice
    } = this.props

    setInvoice({
      ...invoice,
      comments: event.target.value
    })
  }

  handleInvoiceAuditedChange = event => {
    const {
      invoice,
      setInvoice
    } = this.props

    setInvoice({
      ...invoice,
      audited: ~~event.target.checked
    })
  }

  handleGoogleDriveChange = (invoice, pdf) => {
    const {
      setInvoice,
      setNotification
    } = this.props

    setInvoice(invoice)

    if (length(pdf)) {
      this.setState({
        invoicePdfText: pdf,
      }, this.toggleInvoiceParser())
    } else {
      setNotification('Failed to parse invoice.', 'error')
    }
  }

  handleInvoiceJsonUpload = event => {
    if (!event.target.files) return
    const file = event.target.files[0]

    if (file) {
      const fileReader = new FileReader()

      fileReader.onload = event => {
        if (!event.target.result) return
        const { result } = event.target
        const { setInvoiceProducts } = this.props
        setInvoiceProducts(JSON.parse(result))
      }

      fileReader.readAsBinaryString(file)
    }
  }

  handleParseInvoiceSubmit = (products, unmatched) => {
    const {
      restockProducts,
      setInvoiceProducts,
      setNotification
    } = this.props
    if (length(products)) {
      setInvoiceProducts(products)
      setNotification(`Found ${length(products)} of ${length(restockProducts)} products in invoice.`, 'success')
    } else {
      this.setState({ invoiceUnmatched: unmatched })
      setNotification('0 products matched.', 'error')
    }

    this.setState({
      invoicePdfText: [],
    }, this.toggleInvoiceParser())
  }

  handleSaveInvoiceSubmit = () => {
    const {
      invoice,
      invoiceProducts,
      saveDeliveryInvoiceAttempt
    } = this.props
    const { deliveryId } = this.state
    const alteredInvoice = pick([
      'google_drive_id', 'mime_type', 'filename', 'url', 'modified_at',
      'comments', 'audited'
    ], invoice)
    const alteredProducts = map(pick([
      'product_id', 'supplier_code', 'quantity', 'description', 'invoice_cost_ex_vat'
    ]), invoiceProducts)

    saveDeliveryInvoiceAttempt(
      deliveryId,
      alteredInvoice,
      alteredProducts
    )
  }

  // Grid
  headerCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // No label and sorting
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    // Column chooser
    if (equals(columnName, 'restock')) {
      const {
        restockProductsColumns,
        otherProductsColumns,
        visibilityColumnExtensions,
        hiddenColumnNames,
        onColumnVisibilityChange,
      } = this.props
      const {
        tab,
      } = this.state
      const columns = isOtherTab(tab) ? otherProductsColumns : restockProductsColumns
      const disabledColumns = pipe(
        filter(column => !column.togglingEnabled),
        pluck('columnName'),
      )(visibilityColumnExtensions)

      return (
        <GridColumnChooserCell
          columns={columns}
          hiddenColumns={hiddenColumnNames}
          disabledColumns={disabledColumns}
          onChange={onColumnVisibilityChange}
        />
      )
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  rowDetailComponent = ({ row }) => {
    const {
      classes,
      location: routerLocation,
      data,
      ordersColumns,
      ordersColumnExtensions,
    } = this.props
    const pathname = prop('pathname', routerLocation)
    const { received_at: received } = data
    const { orders, ordersSelection } = row
    const onSelectionChange = isOtherTab(this.state.tab)
      ? this.handleGridOtherProductsOrdersSelection
      : this.handleGridRestockProductsOrdersSelection

    return (
      <Paper className={classes.rowDetails} square>
        <ReactGrid
          rows={orders}
          columns={ordersColumns.asMutable()}
        >
          <GridDateTypeProvider
            columns={['order_date']}
          />
          <RowDetailState
            // expandedRowIds={[...Array(orders.length).keys()]}
          />
          <SelectionState
            selection={ordersSelection}
            onSelectionChange={onSelectionChange}
          />
          <IntegratedSelection />
          <Table
            columnExtensions={ordersColumnExtensions.asMutable()}
            rowComponent={this.rowComponent}
            cellComponent={this.cellComponent}
            noDataCellComponent={() =>
              this.cellComponentNoData(length(ordersColumns) + (received ? 0 : 1), 'No orders.')
            }
          />
          <TableHeaderRow
            cellComponent={this.headerCellComponent}
          />
          <TableRowDetail
            contentComponent={({ row }) => {
              if (!isEmpty(row.reserved_products)) {
                return map(product => (
                  <div key={product.sku}>
                    <span className={classes.error}>Reserved:</span> {product.reserved}x {product.description}
                  </div>
                ))(row.reserved_products)
              }
              return (<div>Nothing reserved.</div>)
            }}
          />
          <TableSelection
            showSelectAll={!received}
            showSelectionColumn={!received}
          />
        </ReactGrid>
      </Paper>
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const { classes, location: routerLocation } = this.props
    const pathname = prop('pathname', routerLocation)
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (equals(columnName, 'description')) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Backlink & Replace
    if (equals(columnName, 'description')) {
      const description = path(['row', 'description'], cellProps)
      const productBacklink = path(['row', 'backlink'], cellProps)
      const reserves = pathOr([], ['row', 'reserves'], cellProps)
      const groupedReserves = groupSumBy('order_reference', ['quantity'], reserves)
      const received = path(['data', 'received_at'], this.props)
      const rowId = path(['tableRow', 'rowId'], cellProps)
      const { deliveryId, tab, replacing } = this.state

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {`${cellProps.value} `}
          {productBacklink && (
            <Tooltip
              title="Product backlink"
              placement="top"
            >
              <IconButton
                className={classes.itemIcon}
                size="small"
                onClick={() => window.open(`${productBacklink}&filter_name=${encodeURIComponent(description)}`, '_blank')}
              >
                <IconLaunch color="action" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {(deliveryId && isRestockTab(tab) && isEmpty(reserves) && !received) ? (
            <Button
              className={classes.itemButton}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => this.handleReplacing(rowId)}
            >
              {'Replace'}
            </Button>
          ) : null}
          {(deliveryId && isOtherTab(tab) && replacing) ? (
            <Button
              className={classes.itemButton}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => this.handleReplace(rowId)}
            >
              {'Select'}
            </Button>
          ) : null}
          {length(groupedReserves) ? (
            <div className={join(' ', [classes.itemInfo, classes.error])}>
              <i>{'Reserved: '}</i>{map(reserve => {
                return (
                  <Link
                    key={reserve.order_reference}
                    className={join(' ', [classes.itemInfoLink, classes.error])}
                    to={`/orders/${reserve.order_reference}`}
                    state={{ from: pathname }}
                  >
                    <i>{`${reserve.order_reference} (${reserve.quantity})`}</i>
                  </Link>
                )
              }, groupedReserves)}
            </div>
          ) : null}
        </Table.Cell>
      )
    }

    // Hot icon
    if (equals(columnName, 'hot') && cellProps.value) {
      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <Tooltip
            title="Hot"
            placement="top"
          >
            <IconWhatshot color="error" />
          </Tooltip>
        </Table.Cell>
      )
    }

    // Restock
    if (equals(columnName, 'restock')) {
      const received = path(['data', 'received_at'], this.props)
      const skuCode = path(['row', 'sku'], cellProps)
      const caseSizeRestock = path(['row', 'case_size_restock'], cellProps)
      const onChange = isOtherTab(this.state.tab)
        ? this.handleOtherProductsChange
        : this.handleRestockProductsChange
      const { replacing } = this.state

      const RestockField = (
        <Input
          type="number"
          value={cellProps.value}
          fullWidth
          onChange={({ target }) => onChange(
            skuCode,
            Math.max(1, Number(target.value))
          )}
          disabled={!!received || replacing}
          error={!!caseSizeRestock}
        />
      )

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {
            caseSizeRestock
            ? (
              <Tooltip
                title="Case size restock"
                placement="top"
              >
                {RestockField}
              </Tooltip>
            )
            : RestockField
          }
        </Table.Cell>
      )
    }

    // Warning icon if restock lower than ordered
    if (equals(columnName, 'total_ordered')) {
      // TODO how to calculate if ordering enough?
      // const ordered = path(['row', 'total_ordered'], cellProps)
      // const inStock = path(['row', 'items_in_stock'], cellProps)
      // const notified = path(['row', 'total_notified'], cellProps)
      // const restock = path(['row', 'restock'], cellProps)
      // if (ordered > sum([inStock, notified, restock])) {
      const orders = path(['row', 'orders'], cellProps)
      const ordersSelection = path(['row', 'ordersSelection'], cellProps)

      if (!equals(length(orders), length(ordersSelection))) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            <div className={classes.rowWarning}>
              {cellProps.value}
              <IconWarning className={classes.rowWarningIcon} color="disabled" />
            </div>
          </Table.Cell>
        )
      }
    }

    // Warning icon if product orders have reserved products
    if (equals(columnName, 'items_required')) {
      const orders = path(['row', 'orders'], cellProps)
      const reservedProducts = pipe(
        pluck('reserved_products'),
        flatten,
        reject(isEmpty)
      )(orders)

      if (!isEmpty(reservedProducts)) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            <div className={classes.rowWarning}>
              {cellProps.value}
              <IconWarning className={classes.rowWarningIcon} color="disabled" />
            </div>
          </Table.Cell>
        )
      }
    }

    // Invoice
    if (equals(columnName, 'unmatched')) {
      const { invoiceUnmatched } = this.state
      const { setInvoiceProductCost } = this.props
      const productId = pathOr(null, ['row', 'product_id'], cellProps)
      const invoiceCost = pathOr(null, ['row', 'invoice_cost_ex_vat'], cellProps)

      if (productId && length(invoiceUnmatched) && !invoiceCost) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            <FormControl>
              <Select
                value={''}
                onChange={({ target }) => {
                  invoiceUnmatched.splice(target.value.index, 1)
                  setInvoiceProductCost(productId, target.value.cost)
                }}
                autoWidth
                // label="Unmatched"
              >
                {invoiceUnmatched.map((product, index) => (
                  <MenuItem
                    key={`unmatched-${index}`}
                    value={{
                      index,
                      cost: replace(/£/, '', product.invoice_cost_ex_vat)
                    }}
                  >
                    {product.description} - {product.invoice_cost_ex_vat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Table.Cell>
        )
      }
    }

    if (equals(columnName, 'invoice_cost_ex_vat')) {
      const { setInvoiceProductCost } = this.props
      const productId = pathOr(null, ['row', 'product_id'], cellProps)
      let productCost = pathOr(0, ['row', 'total_cost_ex_vat'], cellProps)
      if (productCost) productCost = Number(productCost).toFixed(2)
      const difference = productCost - cellProps.value

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <Input
            classes={{ root: classes.invoiceInput }}
            type="number"
            value={cellProps.value || ''}
            disabled={!productId}
            onChange={({ target }) =>
              setInvoiceProductCost(productId, target.value)
            }
          />
          {(difference < 0 || difference > 0) && (
            <Typography
              classes={{
                root: (difference < 0) ? classes.error : classes.success
              }}
              variant="caption"
              color="textSecondary"
            >
              &nbsp;{difference > 0 ? '+' : ''}{difference.toFixed(2)}
            </Typography>
          )}
        </Table.Cell>
      )
    }

    // Actions
    if (equals(columnName, 'action')) {
      // TODO when more action columns
      const orderId = path(['row', 'id'], cellProps)
      const orderReference = path(['row', 'order_reference'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <Tooltip
            classes={{ tooltip: classes.warningTooltip }}
            id={`tooltip-${orderId}`}
            title={`Open order ${orderReference} in new tab`}
            placement="left"
          >
            <IconButton
              onClick={() => window.open(`/orders/${orderReference}`, '_blank')}
            >
              <IconVisibility color="action" />
            </IconButton>
          </Tooltip>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = (colSpan, message) => {
    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{message}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      classes,
      history: routerHistory,
      goBack,
      // Grid
      uiLoadingGet,
      uiLoadingSave,
      uiLoadingComplete,
      uiLoadingDelete,
      data,
      suppliers,
      productsColumnExtensions,
      hiddenColumnNames,
      restockProductsColumns,
      restockProductsSorting,
      otherProductsColumns,
      otherProductsSorting,
      // Update supplier
      uiLoadingUpdateSupplier,
      supplier,
      // Invoice
      invoice,
      invoiceProducts,
      invoiceProductsColumns,
      invoiceProductsColumnsExtensions,
      googleToken,
      googleTokenExpires,
      setGoogleToken
    } = this.props
    const {
      supplierId,
      deliveryId,
      expectedDate,
      comments,
      tab,
      restockProducts,
      restockProductsSelection,
      otherProducts,
      otherProductsSelection,
      expandedRowId,
      replacing,
      saved,
      csvData,
      totalItems,
      totalCost,
      editingSupplier,
      deleting,
      invoicePdfText,
      editingInvoiceParser,
    } = this.state
    const { received_at: received } = data
    const getSupplier = findById(supplierId, suppliers)
    const supplierName = propOr('Unknown', 'name', getSupplier)
    const supplierComment = prop('comment', getSupplier)
    let loading = null

    const products = isOtherTab(tab) ? otherProducts : restockProducts
    const selectedRestockProducts = filterSelected(restockProducts, restockProductsSelection)
    const selectedOtherProducts = filterSelected(otherProducts, otherProductsSelection)

    const columns = isOtherTab(tab) ? otherProductsColumns : restockProductsColumns

    const sorting = isOtherTab(tab) ? otherProductsSorting : restockProductsSorting
    const onSortingChange = isOtherTab(tab)
      ? this.handleGridOtherProductsSorting
      : this.handleGridRestockProductsSorting

    const selection = isOtherTab(tab) ? otherProductsSelection : restockProductsSelection
    const onSelectionChange = isOtherTab(tab)
      ? this.handleGridOtherProductsSelection
      : this.handleGridRestockProductsSelection

    const deliveryCost = getTotalDeliveryCost(invoiceProducts)
    const invoiceCost = getTotalInvoiceCost(invoiceProducts)
    const invoiceDifference = deliveryCost - invoiceCost
    const invoiceParser = getInvoiceParser(supplierName)
    const totalDeliveryCost = deliveryCost || totalCost

    if (uiLoadingGet) {
      loading = 'Loading delivery...'
    } else if (uiLoadingDelete) {
      loading = 'Deleting delivery...'
    }

    return (
      <div>
        <Header
          title={`Delivery from ${supplierName}`}
          subtitle={'Order more stock'}
        >
          <GoBackButton
            onBack={goBack}
            history={routerHistory}
          />
          <RefreshButton
            onClick={this.handleMount}
          />
          {!isEmpty(restockProducts) && (
            !isEmpty(selectedRestockProducts) && saved
            ? (
              <Tooltip
                id="tooltip-print"
                title="Print delivery"
                placement="left"
              >
                <IconButton>
                  <PrintButton
                    trigger={() => <IconPrint />}
                    content={() => this.printComponent}
                  />
                </IconButton>
              </Tooltip>
            )
            : (
              <Tooltip
                id="tooltip-csv"
                title={isEmpty(selectedRestockProducts) ? 'Please select restock products first.' : 'Please save changes first.'}
                placement="left"
              >
                <IconButton>
                  <IconPrint color="disabled" />
                </IconButton>
              </Tooltip>
            )
          )}
          {!isEmpty(restockProducts) && (
            !isEmpty(selectedRestockProducts) && saved
            ? (
              <Tooltip
                id="tooltip-csv"
                title="Download CSV"
                placement="left"
              >
                <CSVLink
                  data={csvData}
                  filename={`${replace(/ /g, '-', supplierName)}-${moment().format('DD-MM-YYYY')}.csv`}
                  target="_self"
                >
                  <IconButton>
                    <IconCloudDownload />
                  </IconButton>
                </CSVLink>
              </Tooltip>
            )
            : (
              <Tooltip
                id="tooltip-csv"
                title={isEmpty(selectedRestockProducts) ? 'Please select products first.' : 'Please save changes first.'}
                placement="left"
              >
                <IconButton>
                  <IconCloudDownload color="disabled" />
                </IconButton>
              </Tooltip>
            )
          )}
          {(deliveryId > 0 && !isEmpty(selectedRestockProducts)) && (
            <Tooltip
              id="tooltip-delete"
              title="Delete delivery"
              placement="left"
            >
              <IconButton onClick={this.toggleDeleting}>
                <IconDelete color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Header>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {supplierId > 0 && (
              <div className={classes.comment}>
                <IconWarning color="disabled" />
                <IconButton onClick={() => this.toggleEditingSupplierOpen(supplierId)}>
                  <IconEdit />
                </IconButton>
                <div className={classes.commentText}>
                  {
                    supplierComment
                    ? nl2brArray(supplierComment).map((text, key) => (
                      <Typography key={key} color="textSecondary">{text}</Typography>
                    ))
                    : <Typography color="textSecondary">{'No supplier comment.'}</Typography>
                  }
                </div>
              </div>
            )}
            <Typography>
              {`Received on: ${received ? moment(received).format('DD/MM/YYYY H:mma') : 'not yet'}`}
            </Typography>
            <Typography>
              {`Total Restock Products: ${length(selectedRestockProducts)}`}
            </Typography>
            <Typography>
              {`Total Restock Quantity: ${Number(totalItems)}`}
            </Typography>
            <Typography>
              {`Total Restock Cost: £${Number(totalDeliveryCost).toFixed(2)}`}
            </Typography>
          </Grid>
          {expectedDate && (
            <Grid item xs={12} sm={6} classes={{ item: classes.expectedDate }}>
              <TextField
                variant="outlined"
                margin="dense"
                id="expectedDate"
                label="Expected Date"
                type="date"
                inputProps={{ min: moment().format('YYYY-MM-DD') }}
                value={expectedDate}
                onChange={this.handleExpectedDateChange}
                disabled={isEmpty(restockProducts) || uiLoadingSave || uiLoadingComplete}
                InputProps={{
                  readOnly: !isRestockTab(tab) || !!received
                }}
              />
              {deliveryId > 0 && (
                <TextField
                  variant="outlined"
                  id="deliveryComments"
                  label="Delivery Comments"
                  multiline
                  rows="4"
                  fullWidth
                  value={comments || ''}
                  onChange={this.handleDeliveryCommentsChange}
                  disabled={uiLoadingSave || uiLoadingComplete}
                  InputProps={{
                    readOnly: !isRestockTab(tab) || !!received
                  }}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <AppBar position="static" color="inherit">
                <Tabs
                  value={tab}
                  onChange={this.handleTabsChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="off"
                >
                  <Tab key="tab-restock" label={`Restock Products (${length(restockProducts)})`} value={1} />
                  {!isEmpty(otherProducts) && (
                    <Tab key="tab-other" label={`Other Products (${length(otherProducts)})`} value={2} />
                  )}
                  {deliveryId > 0 && (
                    <Tab
                      key="tab-invoice"
                      className={!invoice.url ? classes.warning : null}
                      label={`Invoice`}
                      value={3}
                    />
                  )}
                </Tabs>
              </AppBar>
              <Divider className={classes.divider} />
              {
                deliveryId > 0 && isInvoiceTab(tab)
                ? (
                  <div className={classes.invoice}>
                    <Grid container spacing={3} className={classes.header}>
                      <Grid item xs={12} md={6} className={classes.invoiceHeader}>
                        <div className={classes.invoiceHeading}>
                          <div className={classes.invoiceHeadingText}>
                            <Typography
                              variant="body1"
                              color="textPrimary"
                            >
                              {invoice.filename || 'No invoice'}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                            >
                              {moment(invoice.modified_at).format('DD/MM/YYYY HH:mm') || 'find invoice on Google Drive'}
                            </Typography>
                          </div>
                          <div className={classes.invoiceHeadingButton}>
                            {(received && !uiLoadingSave && supportsGoogleDrive) && (
                              <Tooltip
                                id={`tooltip-google-drive`}
                                title={`Google Drive`}
                                placement="top"
                              >
                                <GoogleDrivePicker
                                  accessToken={googleToken}
                                  tokenExpires={googleTokenExpires}
                                  apiKey={process.env.REACT_APP_GOOGLE_DRIVE_API_KEY}
                                  clientId={process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID}
                                  parentId={process.env.REACT_APP_GOOGLE_DRIVE_PARENT_ID}
                                  navHidden
                                  onAuth={data => setGoogleToken(data.access_token)}
                                  onAuthFailed={data => console.log('onAuthFailed:', data)}
                                  onChange={this.handleGoogleDriveChange}
                                />
                              </Tooltip>
                            )}
                            {(received && !uiLoadingSave && supportsGoogleDrive) && (
                              <Tooltip
                                id={`tooltip-invoice-parser`}
                                title={`Invoice parser`}
                                placement="top"
                              >
                                <IconButton
                                  onClick={this.toggleInvoiceParser}
                                >
                                  <IconSync color={invoiceParser ? 'action' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {received && !uiLoadingSave && supportsGoogleDrive && !invoiceParser && (
                              <Tooltip
                                id={`tooltip-invoice-json-upload`}
                                title={`Invoice json upload`}
                                placement="top"
                              >
                                <IconButton
                                  component="label"
                                >
                                  <input
                                    type="file"
                                    hidden
                                    onChange={this.handleInvoiceJsonUpload}
                                  />
                                  <IconCloudUpload color="action" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {invoice.url && (
                              <Tooltip
                                classes={{ tooltip: classes.warningTooltip }}
                                id={`tooltip-invoice`}
                                title={`Open invoice in new tab`}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => window.open(invoice.url, '_blank')}
                                >
                                  <IconLaunch color="action" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="invoiceComments"
                          label="Comments"
                          multiline
                          rows="5"
                          fullWidth
                          value={invoice.comments || ''}
                          onChange={this.handleInvoiceCommentsChange}
                          disabled={!received || uiLoadingSave}
                        />
                      </Grid>
                    </Grid>
                    <ReactGrid
                      rows={invoiceProducts}
                      columns={invoiceProductsColumns.asMutable()}
                    >
                      <SortingState
                        defaultSorting={[
                          {
                            columnName: 'description', 'direction': 'asc', compare: naturalSort
                          }
                        ]}
                      />
                      <IntegratedSorting
                        columnExtensions={[
                          { columnName: 'description', compare: naturalSort }
                        ]}
                      />
                      <Table
                        columnExtensions={invoiceProductsColumnsExtensions.asMutable()}
                        rowComponent={this.rowComponent}
                        cellComponent={this.cellComponent}
                        noDataCellComponent={() =>
                          this.cellComponentNoData(
                            length(invoiceProductsColumns),
                            'No invoice products.'
                          )
                        }
                      />
                      <TableHeaderRow />
                    </ReactGrid>
                    <div className={classes.footer}>
                      <Typography
                        variant="body1"
                      >
                        {`Delivery Cost: ${deliveryCost.toFixed(2)}`}
                      </Typography>
                      <Typography
                        variant="body1"
                      >
                        {`Invoice Cost: ${invoiceCost.toFixed(2)}`}
                      </Typography>
                      {(invoiceDifference < 0 || invoiceDifference > 0) && (
                        <Typography
                          classes={{
                            root: (invoiceDifference < 0) ? classes.error : classes.success
                          }}
                          variant="body1"
                          color="textSecondary"
                        >
                          &nbsp;{invoiceDifference > 0 ? '+' : ''}{invoiceDifference.toFixed(2)}
                        </Typography>
                      )}
                    </div>
                  </div>
                )
                : (
                  <ReactGrid
                    rows={products}
                    columns={columns.asMutable()}
                  >
                    <GridCurrencyTypeProvider
                      columns={['cost_ex_vat', 'total_cost_ex_vat']}
                    />
                    {isOtherTab(tab) && (
                      <FilteringState />
                    )}
                    <SortingState
                      columnExtensions={[
                        { columnName: 'restock', sortingEnabled: false },
                      ]}
                      sorting={sorting.asMutable()}
                      onSortingChange={onSortingChange}
                    />
                    <PagingState
                      defaultCurrentPage={0}
                      pageSize={isOtherTab(tab) ? 10 : 0}
                    />
                    {isOtherTab(tab) && (
                      <IntegratedFiltering
                        columnExtensions={[
                          {
                            columnName: 'description',
                            predicate: searchInString
                          },
                        ]}
                      />
                    )}
                    <RowDetailState
                      expandedRowIds={[expandedRowId]}
                      onExpandedRowIdsChange={this.changeExpandedRows}
                    />
                    <SelectionState
                      selection={selection}
                      onSelectionChange={onSelectionChange}
                    />
                    <IntegratedSorting
                      columnExtensions={[
                        { columnName: 'description', compare: naturalSort },
                        { columnName: 'cost_ex_vat', compare: sortDecimal },
                        { columnName: 'total_cost_ex_vat', compare: sortDecimal }
                      ]}
                    />
                    <IntegratedPaging />
                    <IntegratedSelection />
                    <Table
                      columnExtensions={productsColumnExtensions.asMutable()}
                      rowComponent={this.rowComponent}
                      cellComponent={this.cellComponent}
                      noDataCellComponent={() =>
                        this.cellComponentNoData(
                          length(columns) - length(hiddenColumnNames) + (received ? 1 : 2),
                          // TODO length(columns) + (received || isOtherTab(tab) ? 1 : 2),
                          isOtherTab(tab) ? 'No other products.' : 'No stock required.'
                        )
                      }
                    />
                    <TableHeaderRow
                      showSortingControls
                      cellComponent={this.headerCellComponent}
                    />
                    {isOtherTab(tab) && (
                      <TableFilterRow />
                    )}
                    <TableRowDetail
                      contentComponent={this.rowDetailComponent}
                    />
                    <TableSelection
                      showSelectAll={(isRestockTab(tab) && !received) || (isOtherTab(tab) && !replacing)}
                      showSelectionColumn={(isRestockTab(tab) && !received) || (isOtherTab(tab) && !replacing)}
                    />
                    <TableColumnVisibility
                      hiddenColumnNames={hiddenColumnNames}
                    />
                    <PagingPanel />
                  </ReactGrid>
                )
              }
            </Paper>
          </Grid>
          {
            deliveryId > 0 && isInvoiceTab(tab)
            ? (
              <Grid item xs={12} className={classes.buttons}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={!!invoice.audited}
                      onChange={this.handleInvoiceAuditedChange}
                      value="audited"
                      disabled={!received || uiLoadingSave}
                    />
                  }
                  label="Audited"
                  labelPlacement="start"
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={this.handleSaveInvoiceSubmit}
                  disabled={!received || isEmpty(invoice) || uiLoadingSave}
                >
                  {uiLoadingSave
                    ? <CircularProgress color="inherit" size={20} />
                    : 'Save'
                  }
                </Button>
              </Grid>
            )
            : isOtherTab(tab)
            ? (
              <Grid item xs={12} className={classes.buttons}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={this.handleAddToRestock}
                  disabled={isEmpty(selectedOtherProducts)}
                >
                  {`Add to Restock (${length(selectedOtherProducts)})`}
                </Button>
              </Grid>
            )
            : (
              <Grid item xs={12} className={classes.buttons}>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={this.handleSaveSubmit}
                  disabled={!and(!isEmpty(selectedRestockProducts), !saved) || uiLoadingSave || uiLoadingComplete}
                >
                  {uiLoadingSave
                    ? <CircularProgress color="inherit" size={20} />
                    : 'Save'
                  }
                </Button>
                {deliveryId > 0 && (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={this.handleCompleteSubmit}
                    disabled={isEmpty(selectedRestockProducts) || !!received || !saved || uiLoadingComplete || uiLoadingSave}
                  >
                    {uiLoadingComplete
                      ? <CircularProgress color="inherit" size={20} />
                      : 'Received'
                    }
                  </Button>
                )}
              </Grid>
            )
          }
        </Grid>
        <DialogSupplierUpdate
          open={editingSupplier}
          onClose={this.toggleEditingSupplierClose}
          disableBackdropClick={uiLoadingUpdateSupplier}
          disableEscapeKeyDown={uiLoadingUpdateSupplier}
          supplier={supplier}
          onSubmit={this.handleUpdateSupplierSubmit}
          submitting={uiLoadingUpdateSupplier}
        />
        {supportsGoogleDrive && (
          <DialogInvoiceParser
            open={editingInvoiceParser}
            onClose={this.toggleInvoiceParser}
            parser={invoiceParser || {}}
            text={invoicePdfText}
            onSubmit={this.handleParseInvoiceSubmit}
          />
        )}
        {!isEmpty(selectedRestockProducts) && (
          <PrintWrapper ref={c => (this.printComponent = c)}>
            <PrintDelivery
              supplier={supplierName}
              products={selectedRestockProducts}
            />
          </PrintWrapper>
        )}
        {(deliveryId > 0 && !isEmpty(selectedRestockProducts)) && (
          <Dialog
            open={deleting}
            onClose={this.toggleDeleting}
            disableEnforceFocus
            disableRestoreFocus
          >
            <DialogTitle>Are you sure you want to delete this delivery?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                This cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleDeleting}>
                {'Cancel'}
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: '10px'}}
                color="primary"
                onClick={this.handleDeleteDeliverySubmit}
              >
                {'Delete'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {loading && <Loading message={loading} />}
      </div>
    )
  }
}

Delivery.propTypes = {
  classes: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  routerReplace: PropTypes.func.isRequired,
  // Grid
  getDeliveryAttempt: PropTypes.func.isRequired,
  saveDeliveryAttempt: PropTypes.func.isRequired,
  completeDeliveryAttempt: PropTypes.func.isRequired,
  deleteDeliveryAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  uiLoadingSave: PropTypes.bool.isRequired,
  uiLoadingComplete: PropTypes.bool.isRequired,
  uiLoadingDelete: PropTypes.bool.isRequired,
  suppliers: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  productsColumnExtensions: PropTypes.array.isRequired,
  visibilityColumnExtensions: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  restockProducts: PropTypes.array.isRequired,
  restockProductsColumns: PropTypes.array.isRequired,
  restockProductsSorting: PropTypes.array.isRequired,
  onProductsSortingChange: PropTypes.func.isRequired,
  restockProductsSelection: PropTypes.array.isRequired,
  otherProducts: PropTypes.array.isRequired,
  otherProductsColumns: PropTypes.array.isRequired,
  otherProductsSorting: PropTypes.array.isRequired,
  onOtherProductsSortingChange: PropTypes.func.isRequired,
  // Orders Grid
  ordersColumns: PropTypes.array.isRequired,
  ordersColumnExtensions: PropTypes.array.isRequired,
  resetDeliveryState: PropTypes.func.isRequired,
  // Invoice
  setInvoice: PropTypes.func.isRequired,
  setInvoiceProducts: PropTypes.func.isRequired,
  setInvoiceProductCost: PropTypes.func.isRequired,
  saveDeliveryInvoiceAttempt: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  invoiceProducts: PropTypes.array.isRequired,
  invoiceProductsColumns: PropTypes.array.isRequired,
  invoiceProductsColumnsExtensions: PropTypes.array.isRequired,
  // Update supplier
  supplier: PropTypes.object.isRequired,
  setSupplierId: PropTypes.func.isRequired,
  updateSupplierAttempt: PropTypes.func.isRequired,
  uiLoadingUpdateSupplier: PropTypes.bool.isRequired,
  errorSupplier: PropTypes.string.isRequired,
  // Google
  googleToken: PropTypes.string,
  googleTokenExpires: PropTypes.string,
  setGoogleToken: PropTypes.func.isRequired,
}

const {
  setNotification,
  getDeliveryAttempt,
  setDeliveryGridState,
  saveDeliveryAttempt,
  completeDeliveryAttempt,
  deleteDeliveryAttempt,
  setSupplierId,
  updateSupplierAttempt,
  resetDeliveryState,
  setGoogleToken,
  setInvoice,
  setInvoiceProducts,
  setInvoiceProductCost,
  saveDeliveryInvoiceAttempt,
} = Actions

const mapStateToProps = state => ({
  ...state.delivery,
  restockProducts: deliveryRestockProductsSelector(state),
  otherProducts: deliveryOtherProductsSelector(state),
  suppliers: state.app.suppliers,
  supplier: supplierSelector(state),
  uiLoadingUpdateSupplier: pathOr(false, ['suppliers', 'uiLoadingUpdate'], state),
  errorSupplier: pathOr('', ['suppliers', 'error'], state),
  googleToken: state.auth.googleToken,
  googleTokenExpires: state.auth.googleTokenExpires,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  goBack,
  routerReplace,
  setNotification,
  getDeliveryAttempt,
  onColumnVisibilityChange: columnNames => setDeliveryGridState('hiddenColumnNames', columnNames),
  onProductsSortingChange: sorting => setDeliveryGridState('restockProductsSorting', sorting),
  onOtherProductsSortingChange: sorting => setDeliveryGridState('otherProductsSorting', sorting),
  saveDeliveryAttempt,
  completeDeliveryAttempt,
  deleteDeliveryAttempt,
  setSupplierId,
  updateSupplierAttempt,
  resetDeliveryState,
  setGoogleToken,
  setInvoice,
  setInvoiceProducts,
  setInvoiceProductCost,
  saveDeliveryInvoiceAttempt,
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Delivery)
