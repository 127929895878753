import { mapPostcodes } from './shipping-helpers.js'

const scottishHighlands = {
  AB: [[36, 38]],
  FK: [[17, 21]],
  // HS: [],
  // IV: [],
  IV: [[1, 39], [52, 54], [63]],
  // KW: [],
  KW: [[1, 14]],
  // PA: [[20, 49], [60-78], [80]],
  PA: [[21, 40], [80]],
  // PH: [[19, 26], [30, 44], [49, 50]],
  PH: [[19, 26], [30, 41], [49, 50]],
  // ZE: []
}

const scottishIslands = {
  HS: [],
  IV: [[40, 51], [55, 56]],
  KA: [[27, 28]],
  KW: [[15, 17]],
  PA: [[20], [41, 49], [60, 78]],
  PH: [[42, 44]],
  ZE: []
}

const islesOfScilly = {
  TR: [[21, 25]]
}

const postcodesScottishHighlands = mapPostcodes(scottishHighlands)
const postcodesScottishIslands = mapPostcodes(scottishIslands)

const postcodesIslesOfScilly = mapPostcodes(islesOfScilly)
const postcodesIsleOfMan = ['IM']
const postcodesChannelIslands = ['JE', 'GY']
const postcodesNorthernIreland = ['BT']

// TODO disable all but Two Day via API
const offshoreServices = [
  {
    'code': 'nextDay12',
    'name': 'Next Day 12:00',
    '1stParcel': 97.71,
    '2ndParcel+': 97.71,
    'notes': 'per consignment up to 5kg, up to 10kg, then £2.17 per additional kg. Minimum charge per parcel £39.64'
  },
  {
    'code': 'nextDay',
    'name': 'Next Day',
    '1stParcel': 29.53,
    '2ndParcel+': 29.53,
    'notes': 'per consignment up to 10kg, then £1.77 per additional kg'
  },
  {
    'code': 'twoDay',
    'name': 'Two Day',
    '1stParcel': 21.26,
    '2ndParcel+': 21.26,
    'default': true
  },
  {
    'code': 'saturday',
    'name': 'Saturday',
    '1stParcel': 86.53,
    '2ndParcel+': 86.53,
    'notes': 'per consignment up to 5kg, up to 10kg, then £2.17 per additional kg. Minimum charge per parcel £56.87'
  }
]

export default {
  'name': 'DPD',
  'acronym': 'DPD',
  'zones': [
    {
      'name': 'UK Mainland',
      'postcodesInclude': [],
      'postcodesExclude': [
        ...postcodesScottishHighlands,
        ...postcodesScottishIslands,
        ...postcodesIslesOfScilly,
        ...postcodesIsleOfMan,
        ...postcodesChannelIslands,
        ...postcodesNorthernIreland
      ],
      'fuelSurcharge': 16.5,
      'services': [
        {
          'code': 'nextDay1030',
          'name': 'Next Day 10:30',
          '1stParcel': 17.12,
          '2ndParcel+': 17.12,
          'notes': 'up to 5kg £15.94 per pak'
        },
        {
          'code': 'nextDay12',
          'name': 'Next Day 12:00',
          '1stParcel': 10.34,
          '2ndParcel+': 10.34,
          'notes': 'up to 5kg £9.45 per pak'
        },
        {
          'code': 'nextDay',
          'name': 'Next Day',
          '1stParcel': 6.44,
          '2ndParcel+': 6.44,
          'default': true,
          'notes': 'up to 5kg £5.92 per pak'
        },
        {
          'code': 'saturday1030',
          'name': 'Saturday 10:30',
          '1stParcel': 26.58,
          '2ndParcel+': 26.58,
          'notes': 'up to 5kg £25.98 per pak'
        },
        {
          'code': 'saturday12',
          'name': 'Saturday 12:00',
          '1stParcel': 17.12,
          '2ndParcel+': 17.12,
          'notes': 'up to 5kg £15.94 per pak'
        },
        {
          'code': 'saturday',
          'name': 'Saturday',
          '1stParcel': 10.34,
          '2ndParcel+': 10.34,
          'notes': 'up to 5kg £9.45 per pak'
        },
        {
          'code': 'sunday12',
          'name': 'Sunday 12:00',
          '1stParcel': 17.12,
          '2ndParcel+': 17.12,
          'notes': 'up to 5kg £15.94 per pak'
        },
        {
          'code': 'sunday',
          'name': 'Sunday',
          '1stParcel': 10.34,
          '2ndParcel+': 10.34,
          'notes': 'up to 5kg £9.45 per pak'
        }
      ]
    },
    {
      'name': 'Scottish Highlands',
      'postcodesInclude': postcodesScottishHighlands,
      'postcodesExclude': [],
      'fuelSurcharge': 24.5,
      'services': offshoreServices
    },
    {
      'name': 'Scottish Islands',
      'postcodesInclude': postcodesScottishIslands,
      'postcodesExclude': [],
      'fuelSurcharge': 24.5,
      'services': offshoreServices
    },
    {
      'name': 'Isles of Scilly',
      'postcodesInclude': postcodesIslesOfScilly,
      'postcodesExclude': [],
      'fuelSurcharge': 24.5,
      'services': offshoreServices
    },
    {
      'name': 'Isle of Man',
      'postcodesInclude': postcodesIsleOfMan,
      'postcodesExclude': [],
      'fuelSurcharge': 24.5,
      'services': offshoreServices
    },
    {
      'name': 'Northern Ireland',
      'postcodesInclude': postcodesNorthernIreland,
      'postcodesExclude': [],
      'fuelSurcharge': 24.5,
      'services': offshoreServices
    }
  ]
}
