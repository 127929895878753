import {
  pipe, find, propEq, propOr, head, defaultTo
} from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

import couriers from '../mock/shipping'

const INITIAL_STATE = Immutable({
  uiLoadingGet: false,
  couriers: couriers,
  postcode: 'TW7',
  parcels: [1],
  packaging: 'pulp',
  shippingPricesColumns: [
    { name: 'bottles', title: 'Bottles' },
    { name: 'parcels', title: 'Parcels' },
    { name: 'pulp', title: 'Pulp' },
    { name: 'budget', title: 'Budget' },
    { name: 'postage', title: 'Postage' },
    { name: 'totalExVat', title: 'Total (ex VAT)' },
    { name: 'totalIncVat', title: 'Total (inc VAT)' },
  ],
  shippingPricesColumnExtensions: [
    // { columnName: 'bottles', width: 75 },
    // { columnName: 'parcels', width: 60 },
    // { columnName: 'pulp', width: 75 },
    // { columnName: 'budget', width: 75 },
    // { columnName: 'postage', width: 75 },
    // { columnName: 'totalExVat', width: 100 },
    // { columnName: 'totalIncVat', width: 100 }
  ],
  shippingPricesCourier: 'Packfleet',
  shippingPricesZone: 'London',
  shippingPricesCouriers: {}
})

// GET Shipping
// const getShippingAttempt = state => {
//   return state.merge({
//     uiLoadingGet: true,
//   })
// }

// const getShippingSuccess = state => {
//   return state.merge({
//     uiLoadingGet: false,
//   })
// }

// const getShippingFailure = state => {
//   return state.merge({
//     uiLoadingGet: false,
//   })
// }

// PUT Shipping
const setShippingPostcode = (state, { postcode }) => {
  return state.merge({
    postcode: postcode
  })
}

const setShippingParcels = (state, { parcels }) => {
  // TODO URGENT order weight vs bottles !!!
  return state.merge({
    parcels: parcels
  })
}

const setShippingPricesCourier = (state, { courier }) => {
  const initialZone = pipe(
    find(propEq('name', courier)),
    propOr([], 'zones'),
    head
  )(state.couriers)

  // TODO this is not going to work
  const couriers = {
    ...state.shippingPricesCouriers,
    [courier]: initialZone.name
  }

  return state.merge({
    shippingPricesCourier: courier,
    shippingPricesZone: initialZone.name,
    packaging: initialZone.packaging ? initialZone.packaging : 'pulp',
    shippingPricesCouriers: couriers
  })
}

const setShippingPricesZone = (state, { zone }) => {
  const selectedZone = pipe(
    find(propEq('name', state.shippingPricesCourier)),
    propOr([], 'zones'),
    find(propEq('name', zone)),
    defaultTo(null)
  )(state.couriers)

  // TODO this is not going to work
  const couriers = {
    ...state.shippingPricesCouriers,
    [state.shippingPricesCourier]: zone
  }

  return state.merge({
    shippingPricesZone: zone,
    packaging: selectedZone.packaging ? selectedZone.packaging : 'pulp',
    shippingPricesCouriers: couriers
  })
}

const setShippingPackaging = (state, { packaging }) => {
  // NOTE pulp or budget
  return state.merge({
    packaging: packaging
  })
}

const resetShippingState = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Shipping
  // [Types.GET_SHIPPING_ATTEMPT]: getShippingAttempt,
  // [Types.GET_SHIPPING_SUCCESS]: getShippingSuccess,
  // [Types.GET_SHIPPING_FAILURE]: getShippingFailure,
  // PUT Shipping
  [Types.SET_SHIPPING_POSTCODE]: setShippingPostcode,
  [Types.SET_SHIPPING_PARCELS]: setShippingParcels,
  [Types.SET_SHIPPING_PRICES_COURIER]: setShippingPricesCourier,
  [Types.SET_SHIPPING_PRICES_ZONE]: setShippingPricesZone,
  [Types.SET_SHIPPING_PACKAGING]: setShippingPackaging,
  // Reset
  [Types.RESET_SHIPPING_STATE]: resetShippingState,
  [Types.LOGOUT]: resetShippingState
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
