import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { indigo } from '@material-ui/core/colors'

const styles = theme => ({
  '@global': {
    'mark': {
      borderRadius: '2px',
      backgroundColor: indigo['A700'],
      color: theme.palette.common.white,
      padding: '1px 2.5px 1.5px',
      marginLeft: '-2.5px',
      marginRight: '-2.5px'
    },
    'mark::before, mark::after': {
      content: ' [highlight start] ',
      clipPath: 'inset(100%)',
      clip: 'rect(1px, 1px, 1px, 1px)',
      height: '1px',
      width: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap'
    },
    'mark::after': {
      content: ' [highlight end] '
    }
  }
})

const HighlightText = ({ children, highlight = [] }) => {
  if (!highlight.length) return [children]

  const processNode = (node) => {
    if (typeof node === 'string') {
      const regex = new RegExp(`(${highlight.join("|")})`, "gi");
      return node.split(regex).map((part, index) =>
        highlight.some(h => h.toLowerCase() === part.toLowerCase()) ? (
          <mark key={index}>
            {part}
          </mark>
        ) : (
          part
        )
      );
    }
    
    if (React.isValidElement(node)) {
      return React.cloneElement(node, {
        children: React.Children.map(node.props.children, processNode)
      })
    }

    return node
  }

  return [React.Children.map(children, processNode)]
}

HighlightText.propTypes = {
  classes: PropTypes.object.isRequired,
  highlight: PropTypes.array.isRequired
}

HighlightText.defaultProps = {
  highlight: []
}

export default withStyles(styles)(HighlightText)
