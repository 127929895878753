import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
  includes, without, concat, length, join, sum
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  shippingCouriersSelector
} from '../util/selectors'
import {
  addPercentage
} from '../util/helpers'

// Material UI
import { withStyles, fade } from '@material-ui/core/styles'
import { yellow, indigo, pink, green, blueGrey } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Grow from '@material-ui/core/Grow'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import IconSpeakerNotes from '@material-ui/icons/SpeakerNotes'
import IconSpeakerNotesOff from '@material-ui/icons/SpeakerNotesOff'

// Components
import HighlightText from './HighlightText'

const AppSwitch = withStyles({
  root: {
    marginTop: '-2px',
    marginBottom: '-2px'
  },
  switchBase: {
    color: pink[500],
    '& + $track': {
      backgroundColor: pink[500],
    },
    '&$checked': {
      color: green['A400'],
    },
    '&$checked + $track': {
      backgroundColor: green['A400'],
    },
  },
  checked: {},
  track: {},
})(Switch)

const isHighlight = (keyword, highlight) => {
  if (includes(keyword, highlight)) {
    return true
  }
  return false
}

const highlights = ['10:30', '12:00', 'Saturday', 'Sunday']

const styles = theme => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 9,
    marginTop: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: fade(blueGrey[900], 0.8),
    backdropFilter: 'blur(5px)',
    boxShadow: `0 10px 10px ${fade(blueGrey[900], 0.8)}`,
    borderBottom: '1px solid rgba(255, 255, 255, 0.04)'
  },
  content: {
    width: '100%',
    margin: 0,
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  heading: {
    paddingTop: theme.spacing(1.5)
  },
  switch: {
    marginTop: -theme.spacing(0.5),
    '& label': {
      cursor: 'pointer',
      fontSize: 12
    }
  },
  buttonNotes: {
    marginBottom: theme.spacing(0.5)
  },
  buttonNotesIconOn: {
    color: indigo['A100'],
    opacity: 0
  },
  buttonNotesIconOff: {
    position: 'absolute',
    opacity: 0
  },
  buttonHighlight: {
    marginTop: theme.spacing(0.2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.7),
    minWidth: '80px'
  },
  // TODO sticky
  courierHeading: {
    // position: 'sticky',
    // top: 0
  },
  zoneHeading: {
    color: indigo['A100'],
    fontSize: 10,
    fontStyle: 'italic',
    marginBottom: theme.spacing(0.5)
  },
  zone: {
    marginBottom: theme.spacing(1)
  },
  service: {
    borderTop: '1px solid rgba(255, 255, 255, 0.08)',
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.035)',
    }
  },
  default: {
    '& *': {
      opacity: '1 !important',
      color: `${theme.palette.error.main} !important`,
      fontWeight: 500,
    }
  },
  meta: {
    fontSize: 10,
    fontStyle: 'italic',
    opacity: 0.8
  },
  info: {
    fontSize: 10,
    fontStyle: 'italic',
    // color: yellow['A400'],
    color: indigo['A100']
  },
  price: {
    fontSize: 12,
    color: yellow['A400']
  },
  total: {
    fontWeight: 500,
    color: indigo['A100']
  },
  empty: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontStyle: 'italic'
  }
})


class CourierPrices extends PureComponent {
  state = {
    highlight: [],
    showNotes: false
  }

  // TODO reset state when leave container?
  // componentWillUnmount() {
  //   console.log('UNMOUNT');
    
  //   this.props.resetShippingState()
  // }

  toggleNotes = () => {
    this.setState({
      showNotes: !this.state.showNotes
    })
  }

  handlePackagingChange = event => {
    const packaging = event.target.checked ? 'budget' : 'pulp'
    this.props.setShippingPackaging(packaging)
  }

  handleHighlightChange = keyword => {
    const { highlight } = this.state

    if (isHighlight(keyword, highlight)) {
      this.setState({
        highlight: without([keyword], highlight)
      })
    } else {
      this.setState({
        highlight: concat(highlight, [keyword])
      })
    }
  }

  render() {
    const {
      classes,
      postcode,
      parcels,
      couriers,
      packaging
    } = this.props
    const {
      highlight,
      showNotes
    } = this.state

    return (
      <div>
        <Grid container spacing={1} direction="row" justify="space-between" alignItems="flex-end" className={classes.header}>
          <Grid item>
            <Typography className={classes.heading} variant="h6">
              {postcode ? postcode : 'No postcode?'}
            </Typography>
            {
              length(couriers)
              ? (
                <FormControl className={classes.switch}>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={0}>
                      <Grid item>pulp</Grid>
                      <Grid item>
                        <AppSwitch
                          checked={packaging === 'budget'}
                          onChange={this.handlePackagingChange}
                          name="packaging"
                          size="small"
                        />
                      </Grid>
                      <Grid item>budget</Grid>
                    </Grid>
                  </Typography>
                </FormControl>
              )
              : null
            }
            {
              length(couriers)
              ? (
                <div className={classes.meta}>
                  {`parcels: ${JSON.stringify(parcels)}`}
                </div>
              )
              : null
            }
          </Grid>
          {
            length(couriers)
            ? (
              <Grid item>
                {highlights.map((words, key) => (
                  <Button
                    key={key}
                    className={classes.buttonHighlight}
                    size="small"
                    variant={isHighlight(words, highlight) ? 'contained' : 'outlined'}
                    color={isHighlight(words, highlight) ? 'primary' : 'default'}
                    onClick={() => this.handleHighlightChange(words)}
                  >
                    {words}
                  </Button>
                ))}
                <Tooltip
                  title={showNotes ? 'Hide notes' : 'Show notes'}
                  placement="bottom"
                >
                  <IconButton
                    className={classes.buttonNotes}
                    onClick={this.toggleNotes}
                  >
                    <Grow in={showNotes}>
                      <IconSpeakerNotes className={classes.buttonNotesIconOn} />
                    </Grow>
                    <Grow in={!showNotes}>
                      <IconSpeakerNotesOff className={classes.buttonNotesIconOff} />
                    </Grow>
                  </IconButton>
                </Tooltip>
              </Grid>
            )
            : null
          }
        </Grid>
        {/* <Divider className={classes.divider} /> */}
        {
          length(couriers)
          ? (
            <Grid container spacing={2} wrap="wrap" className={classes.content}>
              {couriers.map((courier, key) => (
                <Grid key={key} item sm>
                  <Typography variant="h6" className={classes.courierHeading}>
                    {courier.name}
                  </Typography>
                  {courier.zones.map((zone, key) => (
                    <div key={key}>
                      <Typography className={classes.zoneHeading} variant="body2">
                        {zone.name}
                      </Typography>
                      <div className={classes.zone}>
                        <HighlightText highlight={highlight}>
                          {zone.services.map((service, key) => (
                            <div key={key} className={join(' ', [classes.service, service.default ? classes.default : null])}>
                              
                              
                              <Typography variant="body1">
                                {`${service.name} - `}<span className={classes.price}>&pound;{service.costs.postage}</span>
                              </Typography>
                              <Typography className={classes.meta} variant="body2">
                                {`base: £${service.costs.base}`}
                              </Typography>
                              <Typography className={classes.meta} variant="body2">
                                {`+ surcharge: £${service.costs.surcharge}`}
                              </Typography>
                              <Typography className={classes.meta} variant="body2">
                                {`+ fuel: £${service.costs.fuel}`}
                              </Typography>
                              <Typography className={classes.total} variant="body2">
                                {`= postage: £${service.costs.postage}`}
                              </Typography>
                              <Typography className={classes.meta} variant="body2">
                                {`+ packaging: £${service.costs.packaging}`}
                              </Typography>
                              <Typography className={classes.total} variant="body2">
                                {`= total ex vat: £${service.costs.totalExVat}`}
                              </Typography>
                              <Typography className={classes.meta} variant="body2">
                                {`+ vat: £${service.costs.vat}`}
                              </Typography>
                              <Typography className={classes.total} variant="body2">
                                {`= total inc vat: £${service.costs.totalIncVat}`}
                              </Typography>


                              <Collapse in={showNotes}>
                                <Typography className={classes.meta} variant="body2">
                                  {`Parcel: 1st £${service['1stParcel']} / 2nd+ £${service['2ndParcel+']}`}
                                </Typography>
                                <Typography className={classes.meta} variant="body2">
                                  {`Fuel surcharge: ${zone.fuelSurcharge}%`}
                                </Typography>
                              </Collapse>
                              {
                                service.surcharge || zone.surcharge
                                ? (
                                  <Collapse in={showNotes}>
                                    <Typography className={classes.meta} variant="body2">
                                      {`Surcharge: £${service.surcharge || zone.surcharge}`}
                                    </Typography>
                                  </Collapse>
                                )
                                : null
                              }
                              {
                                service.notes
                                ? (
                                  <Collapse in={showNotes}>
                                    <Typography className={classes.info} variant="body2">
                                      {`Notes: ${service.notes}`}
                                    </Typography>
                                  </Collapse>
                                )
                                : null
                              }
                            </div>
                          ))}
                        </HighlightText>
                      </div>
                    </div>
                  ))}
                </Grid>
              ))}
            </Grid>
          )
          : (
            <Typography className={classes.empty} align="center" variant="body2">
              {postcode ? 'No services found for given postcode.' : 'Enter postcode to see options.'}
            </Typography>
          )
        }
      </div>
    )
  }
}

CourierPrices.propTypes = {
  classes: PropTypes.object.isRequired,
  postcode: PropTypes.string.isRequired,
  couriers: PropTypes.array.isRequired,
  packaging: PropTypes.string.isRequired,
  setShippingPackaging: PropTypes.func.isRequired
}

const {
  setShippingPackaging
} = Actions

const mapStateToProps = state => ({
  ...state.shipping,
  couriers: shippingCouriersSelector(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  setShippingPackaging
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(CourierPrices)
