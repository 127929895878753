import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { compose } from 'redux'
import { equals, length, pipe, map, startsWith, flatten, isEmpty, without } from 'ramda'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import AddIcon from '@material-ui/icons/AddCircle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { groupSumBy } from '../util/helpers'


const parseText = (text, parser) => {
  const parsed = pipe(
    map(page => {
      let blocks = []
      let startBlock = null
      let stopBlock = null
      if (Number.isInteger(parser.startAfter)) {
        startBlock = parser.startAfter
      }

      page.map((string, index) => {
        if (!startBlock && startsWith(parser.startAfter, string)) {
          startBlock = index
        }
        if (!stopBlock && Number.isInteger(startBlock)) {
          map(needle => {
            if (startsWith(needle, string)) stopBlock = index
          }, parser.stopBefore)
        }

        if (
          Number.isInteger(startBlock)
          && index > startBlock
          && (!stopBlock)
        ) {
          blocks.push(string)
        }
      })

      return blocks
    }),
    flatten
  )(text)
  console.log('parsed', parsed);

  return parsed
}

const splitToChunks = (arr, chunkSize) => arr.reduce((resultArray, item, index) => {
  const chunkIndex = Math.floor(index/chunkSize)

  if (!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
  }

  resultArray[chunkIndex].push(item)

  return resultArray
}, [])

const numberWithoutCommas = val => val.toString().replace(/,/g, '')

const styles = theme => ({
  row: {
    '&:first-child th': {
      borderTop: '1px solid rgba(255, 255, 255, .12)'
    }
  },
  cell: {
    whiteSpace: 'nowrap',
    '&:not(:first-child)': {
      borderLeft: '1px solid rgba(255, 255, 255, .12)'
    }
  },
  dialogInfo: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  columnsSelect: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  columnSelect: {
    minWidth: 120
  }
})

class DialogInvoiceParser extends PureComponent {
  state = {
    text: [],
    columns: 1,
    columnCode: 0,
    columnName: 0,
    columnCost: 0
  }

  componentDidMount() {
    const { text, parser } = this.props
    this.setInitialState(text, parser)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!equals(this.props.text, nextProps.text)) {
      const { text, parser } = nextProps
      this.setInitialState(text, parser)
    }
  }

  setInitialState = (text, parser) => {
    let parsedText = []
    if (!isEmpty(parser)) {
      parsedText = parseText(text, parser)
    } else {
      console.warn('mount missing parser', parser);
    }

    this.setState({
      text: parsedText,
      columns: parser.columns || 1,
      // - 1 because index starts with 0
      columnCode: (parser.columnCode - 1) || 0,
      columnName: (parser.columnName - 1) || 0,
      columnCost: (parser.columnCost - 1) || 0
    })
  }

  refresh = () => {
    const { text, parser } = this.props
    this.setInitialState(text, parser)
  }

  handleColumnsChange = event => {
    this.setState({
      columns: event.target.value
    })
  }

  handleColumnChange = (column, index) => {
    this.setState({
      [column]: index
    })
  }

  addCell = index => {
    const {
      text
    } = this.state
    // index + 1 to add column after given index
    const alteredText = [
      // part of the array before the specified index
      ...text.slice(0, index + 1),
      // inserted item
      '',
      // part of the array after the specified index
      ...text.slice(index + 1)
    ]

    this.setState({
      text: alteredText
    })
  }

  removeCell = index => {
    const {
      text
    } = this.state
    const alteredText = text.filter(function(val, i) {
      return i !== index;
    })

    this.setState({
      text: alteredText
    })
  }

  handleSubmit = () => {
    const { parser } = this.props
    const {
      text,
      columns,
      columnCode,
      columnName,
      columnCost
    } = this.state
    let unmatchedProducts = []
    const products = pipe(
      items => {
        const chunks = splitToChunks(items, columns)
        const products = map(product => {
          let code = product[columnCode]
          if (code && typeof parser.codeFunction === 'function') {
            code = parser.codeFunction(code)
          }
          const cost = product[columnCost].replace('£', '').trim()
          if (code) {
            return {
              supplier_code: code,
              description: product[columnName] || '',
              invoice_cost_ex_vat: cost || null,
            }
          } else {
            unmatchedProducts.push({
              supplier_code: null,
              description: product[columnName] || '',
              invoice_cost_ex_vat: cost || null,
            })
          }
        }, chunks)

        return without([undefined], products)
      },
      items => {
        // group by code and sum cost
        return pipe(
          groupSumBy('supplier_code', ['invoice_cost_ex_vat']),
          map(product => {
            let invoiceCost = '0.00'
            if (product.invoice_cost_ex_vat) {
              invoiceCost = numberWithoutCommas(product.invoice_cost_ex_vat)
            }

            return {
              ...product,
              invoice_cost_ex_vat: Number(invoiceCost).toFixed(2)
            }
          })
        )(items)
      }
    )(text)
    console.log('products', products);
    console.log('unmatched', unmatchedProducts);

    this.setState({
      text: []
    }, this.props.onSubmit(products, unmatchedProducts))
  }

  render() {
    const {
      classes,
      open,
      onClose,
      parser
    } = this.props
    const {
      text,
      columns,
      columnCode,
      columnName,
      columnCost
    } = this.state
    const rows = splitToChunks(text, columns)
    const totalColumns = Array.from({ length: columns }, (_, i) => i)
    const columnsOptions = Array.from({ length: 20 }, (_, i) => i + 1)

    return (
      <Dialog
        fullScreen
        scroll={'paper'}
        open={open}
        onClose={onClose}
        disableEnforceFocus
        disableRestoreFocus
      >
        <DialogTitle>
          Invoice parser: {parser.name || 'n/a'}
          <br />
          {
            length(parser.notes)
            ? parser.notes.map((note, index) => (
              <Typography
                key={index}
                variant="caption"
                color="textSecondary"
                display="block"
              >
                <em>{note}</em>
              </Typography>
            ))
            : null
          }
        </DialogTitle>
        <div className={classes.dialogInfo}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                color="textSecondary"
              >
                Start after: {parser.startAfter || 'n/a'}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                gutterBottom
              >
                Stop before: {length(parser.stopBefore) ? parser.stopBefore.join(', ') : 'n/a'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} classes={{ item: classes.columnsSelect }}>
              <FormControl variant="outlined">
                <InputLabel>Columns</InputLabel>
                <Select
                  value={columns}
                  onChange={this.handleColumnsChange}
                  autoWidth
                  label="Columns"
                >
                  {columnsOptions.map(value => (
                    <MenuItem key={value} value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <DialogContent>
          <TableContainer>
            <Table>
              {
                (length(rows) && length(totalColumns)) ? (
                  <TableHead>
                    <TableRow className={classes.row}>
                      {totalColumns.map(column => {
                        let selected = ''
                        if (columnCode === column) selected = 'columnCode'
                        if (columnName === column) selected = 'columnName'
                        if (columnCost === column) selected = 'columnCost'
                        return (
                          <TableCell key={`column-${column}`} className={classes.cell}>
                            <FormControl variant="outlined" className={classes.columnSelect}>
                              <InputLabel>{`Column ${column + 1}`}</InputLabel>
                              <Select
                                value={selected}
                                onChange={event => this.handleColumnChange(event.target.value, column)}
                                label={`Column ${column + 1}`}
                              >
                                <MenuItem value={'columnCode'}>
                                  Supplier Code
                                </MenuItem>
                                <MenuItem value={'columnName'}>
                                  Description
                                </MenuItem>
                                <MenuItem value={'columnCost'}>
                                  Total Cost
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                ) : null
              }
              <TableBody>
                {
                  length(rows)
                  ? rows.map((rowColumns, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {rowColumns.map((column, columnIndex) => {
                        const index = (rowIndex * columns) + columnIndex
                        return (
                          <TableCell
                            key={columnIndex}
                            className={classes.cell}
                          >
                            {column || '\u00A0'}
                            <div>
                              <Tooltip
                                title={'Remove cell'}
                                placement="top"
                              >
                                <IconButton
                                  color="primary"
                                  onClick={() => this.removeCell(index)}
                                >
                                  <RemoveIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={'Add cell'}
                                placement="top"
                              >
                                <IconButton
                                  color="secondary"
                                  onClick={() => this.addCell(index)}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                  : (
                    <TableRow>
                      <TableCell align="center">{'0 invoice items found.'}</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <br />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="outlined"
            onClick={this.refresh}
          >
            {'Refresh'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!length(text)}
            onClick={this.handleSubmit}
          >
            {'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogInvoiceParser.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  parser: PropTypes.object.isRequired,
  text: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default compose(
  withMobileDialog(), withStyles(styles)
)(DialogInvoiceParser)
