import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { isEmpty, join, equals, or, length, remove } from 'ramda'
import { compose } from 'redux'
import html2pdf from 'html2pdf.js'
import { nl2brArray } from '../util/helpers'

// Material UI
import { withStyles, fade } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import IconDelete from '@material-ui/icons/Delete'

// Components
import Loading from '../components/Loading'

const updateGiftMessage = (orders, index, message) => 
  index >= 0 && index < orders.length 
      ? orders.map((order, i) => i === index ? { ...order, gift_message: message } : order)
      : orders


const styles = theme => ({
  isLoading: {},
  title: {
    textAlign: 'center'
  },
  content: {
    paddingTop: 0
  },
  messages: {
    display: 'grid',
    // overflow: 'auto',
  },
  row: {
    // pageBreak: 'none',
    position: 'relative',
    display: 'flex',
    justifySelf: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    // TODO width proportions
    // 298 x 420
    // 397 x 559
    // 620 x 874
    // TODO is this correct size ???
    width: 397,
    height: 559,
    // overflow: 'hidden',
    backgroundColor: 'white',
    '$content &': {
      marginBottom: theme.spacing(2),
      // marginBottom: '1px',
    },
    '$isLoading &': {
      backgroundColor: fade(theme.palette.common.white, 0.4),
      backdropFilter: 'blur(1px)',
    }
  },
  sticky: {
    position: 'sticky',
    zIndex: 4,
    top: 0,
    width: '100%',
    height: '40px',
    marginTop: '-519px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.secondary.main, 0.85),
    backdropFilter: 'blur(1px)',
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: '0.75px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingTop: theme.spacing(5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    // backgroundColor: 'rgba(0, 0, 0, 0.2)',
    width: '100%'
  },
  message: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    color: 'black',
    lineHeight: 1.5,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line'
  },
  input: {
    position: 'absolute',
    zIndex: 3,
    // backgroundColor: 'rgba(0, 0, 0, 0.2)'
  },
  frame: {
    position: 'relative',
    '&::before': {
      border: `1px dashed ${theme.palette.info.main}`,
      bottom: theme.spacing(-2),
      content: "''",
      left: theme.spacing(-1),
      position: 'absolute',
      right: theme.spacing(-1),
      top: theme.spacing(-2)
    },
    '&::after': {
      backgroundColor: fade(theme.palette.info.main, 0.85),
      borderRadius: 2,
      color: '#fff',
      content: "'edit'",
      // content: "attr(data-order-reference)",
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: '0.75px',
      height: '20px',
      lineHeight: '20px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      position: 'absolute',
      right: theme.spacing(-1.5),
      top: theme.spacing(-3.25),
      zIndex: 2
    }
  },
  remove: {
    position: 'absolute',
    right: theme.spacing(1)
  }
})


class DialogGiftMessages extends Component {
  state = {
    uiLoading: false,
    orders: this.props.orders
  }

  // componentDidMount() {
  //   // this.handleMount()
  // }

  // componentWillUnmount() {
  //   console.log('GIFTS :: unmount');
  // }

  // handleMount = () => {
    
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Messages changed?
    if (
      !equals(this.props.orders, nextProps.orders)
    ) {
      // console.log('GIFTS :: changed OLD', this.props.orders);
      // console.log('GIFTS :: changed NEW', nextProps.orders);
      this.setState({
        orders: nextProps.orders
      })
    }
    // Dialog closed?
    if (
      !equals(this.props.open, nextProps.open) && !nextProps.open
    ) {
      this.setState({
        orders: nextProps.orders
      })
    }
  }

  // TODO this does not work ???
  shouldComponentUpdate(nextProps, nextState) {
    // console.log('GIFTS :: should update', !equals(this.props, nextProps), !equals(this.state, nextState));
    // console.log('TEST', this.state.orders, nextState.orders);
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  handleMessageChange = index => event => {
    const { orders } = this.state
    // TODO better
    const updatedOrders = updateGiftMessage(orders, index, event.target.value)
    this.setState({
      orders: updatedOrders
    })
  }

  remove = index => event => {
    const { orders } = this.state
    const updatedOrders = remove(index, 1, orders)
    this.setState({
      orders: updatedOrders
    })
  }

  reset = () => {
    this.setState({ orders: this.props.orders })
  }

  print = () => {
    this.setState({ uiLoading: true })
    
    const element = this.messagesComponent
    const options = {
      margin: 0,
      image: { type: 'webp', quality: 0.98 },
      enableLinks: false,
      // TODO pagebreak does not work !!!
      // pagebreak: {
      //   // mode: 'avoid-all',
      //   before: '[pagebreak]',
      // },
      html2canvas: {
        scale: 4,
        letterRendering: true,
        // onclone: (doc) => {
        //   console.log('CLONE', doc);
        // }
      },
      jsPDF: {
        unit: 'in',
        format: 'a6',
        orientation: 'portrait'
      }
    }

    html2pdf()
      .set(options)
      .from(element)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        window.open(pdf.output('bloburl'), '_blank', 'width=500, height=750, location=1, status=1, scrollbars=1, resizable=1, directories=1, toolbar=1, titlebar=1')
      })
      .catch((error) => {
        alert(error)
      })
      .finally(() => {
        this.setState({ uiLoading: false })        
      })
  }

  render() {
    const {
      fullScreen,
      classes,
      open,
      onClose
    } = this.props
    const {
      uiLoading,
      orders
    } = this.state
    
    return (
      <Dialog
        fullScreen={fullScreen}
        // fullWidth
        open={open}
        onClose={onClose}
        disableBackdropClick={uiLoading}
        disableEscapeKeyDown={uiLoading}
        disableEnforceFocus
        disableRestoreFocus
      >
        <DialogTitle classes={{ root: classes.title }}>
          {`Print Gift Message${length(orders) > 1 ? 's' : ''}`}
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }} className={`${uiLoading ? classes.isLoading : null}`}>
          <div
            ref={c => (this.messagesComponent = c)}
            className={classes.messages}
          >
            {orders.map((order, index) => {
              // let attrs = {}
              // if (index > 0) {
              //   attrs = {'pagebreak': ''}
              // }
              return (
                <div
                  key={`gift-message-${index}`}
                  className={classes.row}
                  // {...attrs}
                >
                  {!uiLoading && (
                    <div className={classes.sticky}>
                      {`Order: ${order.order_reference}`}
                      <Tooltip
                        id="tooltip-remove-gift-message"
                        title="Remove gift message"
                        placement="top"
                      >
                        <IconButton
                          size="small"
                          classes={{ root: classes.remove }}
                          onClick={this.remove(index)}
                          disabled={uiLoading}
                        >
                          <IconDelete />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {
                    uiLoading
                    ? (
                      <div className={classes.item}>
                        <div className={classes.message}>
                          {nl2brArray(order.gift_message).map((text, key) => (
                            <div key={key} dangerouslySetInnerHTML={{
                              __html: text || '&nbsp;'
                            }}></div>
                          ))}
                        </div>
                      </div>
                    )
                    : (
                      <div className={join(' ', [classes.item, classes.input])}>
                        <Input
                          fullWidth
                          multiline
                          disableUnderline
                          classes={{ root: classes.frame }}
                          // data-order-reference={order.order_reference}
                          inputProps={{
                            className: classes.message
                          }}
                          value={order.gift_message}
                          onChange={this.handleMessageChange(index)}
                        />
                      </div>
                    )
                  }
                </div>
              )
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={uiLoading}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: '10px'}}
            onClick={this.reset}
            disabled={uiLoading}
          >
            {'Reset'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '10px'}}
            onClick={this.print}
            disabled={isEmpty(orders) || uiLoading}
          >
            {uiLoading
              ? <CircularProgress color="inherit" size={20} />
              : 'Print'
            }
          </Button>
        </DialogActions>
        {uiLoading && (
          <Loading
            variant="absolute"
            message={`Creating gift message${length(orders) > 1 ? 's' : ''}...`}
          />
        )}
      </Dialog>
    )
  }
}

DialogGiftMessages.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired
}

DialogGiftMessages.defaultProps = {
  orders: []
}

export default compose(
  withMobileDialog(), withStyles(styles)
)(DialogGiftMessages)
