import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  equals, or, update, append, init, toUpper
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'

// Material UI
import { withStyles } from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
// import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
// import Button from '@material-ui/core/Button'

// Components
import Header from '../components/Header'
// import RefreshButton from '../components/RefreshButton'
// import Loading from '../components/Loading'
import CourierFormParcels from '../components/CourierFormParcels'
import CourierPrices from '../components/CourierPrices'

const styles = theme => ({
  filter: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  // divider: {
  //   marginBottom: theme.spacing(1),
  // },
  results: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  filter2: {
    paddingTop: theme.spacing(0.5),
  }
})


class ShippingInfo extends Component {
  // state = {
    
  // }

  componentDidMount() {
    this.handleMount()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  // componentWillUnmount() {}

  handleMount = () => {
    // NOTE force re-render on mount (so drawer closes)
    this.props.setShippingParcels(this.props.parcels)
  }

  handlePostcodeChange = event => {
    this.props.setShippingPostcode(toUpper(event.target.value))
  }

  handleParcelChange = key => event => {
    const weight = Math.max(1, Number(event.target.value))
    const parcels = update(key, weight, this.props.parcels)
    this.props.setShippingParcels(parcels)
  }

  handleAddParcel = () => {
    const parcels = append(1, this.props.parcels)
    this.props.setShippingParcels(parcels)
  }

  handleRemoveParcel = () => {
    const parcels = init(this.props.parcels)
    this.props.setShippingParcels(parcels)
  }

  render() {
    const {
      classes,
      postcode,
      parcels
    } = this.props
    // const {
      
    // } = this.state

    return (
      <div>
        <Header
          title={'Shipping Information'}
          subtitle={'Calculate estimated shipping cost. (UK, Isle of Man and Northern Ireland only)'}
        >
          {/* <RefreshButton
            onClick={this.handleMount}
          /> */}
        </Header>
        <Paper className={classes.filter}>
          <Grid container spacing={3} className={classes.filter2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                // margin="normal"
                variant="outlined"
                label="Postcode (outward)"
                value={postcode}
                autoComplete="off"
                onChange={this.handlePostcodeChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CourierFormParcels
                parcels={parcels}
                onParcelChange={this.handleParcelChange}
                onAddParcel={this.handleAddParcel}
                onRemoveParcel={this.handleRemoveParcel}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.results}>
          <CourierPrices />
        </Paper>
      </div>
    )
  }
}

ShippingInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  postcode: PropTypes.string.isRequired,
  parcels: PropTypes.array.isRequired,
  setShippingPostcode: PropTypes.func.isRequired,
  setShippingParcels: PropTypes.func.isRequired,
  // resetShippingState: PropTypes.func.isRequired
}

const {
  setShippingPostcode,
  setShippingParcels,
  // resetShippingState
} = Actions

const mapStateToProps = state => ({
  postcode: state.shipping.postcode,
  parcels: state.shipping.parcels
})

const mapDispatchToProps = dispatch => bindActionCreators({
  setShippingPostcode,
  setShippingParcels,
  // resetShippingState
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(ShippingInfo)
